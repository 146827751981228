import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';

interface Props {
  label: string;
  id: string;
  name: string;
  placeholder: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: () => void;
  error: string;
}

export default function AuthInput({
  label,
  id,
  name,
  placeholder,
  value,
  onChange,
  onBlur,
  error,
}: Props) {
  // eslint-disable-next-line react-redux/useSelector-prefer-selectors
  const { darkMode } = useSelector((state: RootState) => state.auth);
  return (
    <label htmlFor={id} className="LoginForm__label">
      {label}
      <input
        className={`LoginForm__input ${error && 'LoginForm__input--error'}`}
        id={id}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
      {error && (
        <div className="LoginForm__tooltip">
          <div className="LoginForm__arrow" />
          {error}
        </div>
      )}
    </label>
  );
}
