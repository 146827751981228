/* eslint-disable */
import React from 'react';
import ReactMarkdown from 'react-markdown';
import './Message.scss';
import remarkGfm from 'remark-gfm';
import { link } from 'fs';

interface MessageProps {
  message: string;
  position: 'left' | 'right';
}

function LinkRenderer(props: any) {
  return (
    <a href={props.href} target="_blank" rel="noreferrer">
      {props.children}
    </a>
  );
}

export default function Message({ message, position }: MessageProps) {
  return (
    <div className={`message message--${position}`}>
      <ReactMarkdown
        className="message__text"
        children={message}
        remarkPlugins={[remarkGfm]}
        linkTarget="_blank"
      />
      {/* <span className={`decor decor--${position}`}>
        <svg
          width="37"
          height="25"
          viewBox="0 0 37 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.18052 24.0576C0.18052 24.0576 6.45855 24.2208 10.481 24C10.481 24 16.0065 23.5653 19.481 23C23.0814 22.4142 25.0147 21.6364 28.481 20.5C31.6515 19.4605 36.481 17.5 36.481 17.5L22.614 0.0458846C22.614 0.0458846 20.832 3.74649 19.481 6C17.8934 8.64792 16.9917 10.1572 14.981 12.5C12.7311 15.1214 11.1835 16.3482 8.48096 18.5C5.42916 20.9299 0.18052 24.0576 0.18052 24.0576Z"
            fill="#C4DDFF"
          />
        </svg>
      </span> */}
    </div>
  );
}
