import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';

import { Artifact } from '../../../../types/templates';
import DashboardCreatedCard from '../../../Cards/DashboardCreatedCard';
import SearchComponent from '../../../SearchComponent';
import './DashboardList.scss';
import Filter from '../../../Filter';
import {
  getTemplatesTypes,
  resetArtifacts,
} from '../../../../redux/slices/templateSlice';

type Props = {
  setSkip: React.Dispatch<React.SetStateAction<number>>;
  setValue: (arg: string) => void;
  setFilter: (arg: string[]) => void;
};

export default function DashboardList({ setSkip, setValue, setFilter }: Props) {
  const dispatch = useDispatch<AppDispatch>();
  // eslint-disable-next-line react-redux/useSelector-prefer-selectors
  const { artifacts, types } = useSelector(
    (state: RootState) => state.templates,
  );

  React.useEffect(() => {
    dispatch(getTemplatesTypes());
  }, []);

  return (
    <div className="DashboardList">
      <div className="DashboardList__header">
        <h2 className="DashboardList__title">All Created Outputs</h2>
        <div style={{ marginRight: '32px' }}>
          <Filter
            items={types}
            setFilter={(args) => {
              setFilter(args);
              setSkip(0);
              dispatch(resetArtifacts());
            }}
          />
        </div>
        <SearchComponent setSearhValue={setValue} setSkip={setSkip} />
      </div>
      <div className="DashboardList__list">
        {artifacts?.map((item: Artifact) => (
          <DashboardCreatedCard
            key={item?.id}
            text={item?.text}
            id={item?.id}
          />
        ))}
      </div>
    </div>
  );
}
