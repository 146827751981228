import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { RootState } from '../../../redux/store';

import logo from '../../../images/utils-icons/AgileGPT.svg';
import alert from '../../../images/utils-icons/alert-circle.svg';

import ForgotPass from '../ForgotPass';
import Login from '../Login';
import SignIn from '../SignIn';
import Loader from '../../Loader';
import './LoginPage.scss';
import Logo from '../../Logo';
import DarkModeToggle from '../../DarkModeToggle';

export default function LoginPage() {
  const navigate = useNavigate();

  // eslint-disable-next-line react-redux/useSelector-prefer-selectors
  const { loading, errorMessageSignUp, errorMessage, darkMode } = useSelector(
    (state: RootState) => state.auth,
  );

  return loading ? (
    <Loader />
  ) : (
    <div className="LoginPage">
      <div className="LoginPage__toggle">
        <DarkModeToggle />
      </div>
      {(errorMessageSignUp || errorMessage) && (
        <div className="LoginPage__error">
          <img src={alert} alt="alert" />
          {errorMessageSignUp || errorMessage}
        </div>
      )}
      <div style={{ marginBottom: '24px' }}>
        <Logo auth />
      </div>
      <Routes>
        <Route element={<Login />} path="/*" />
        <Route element={<SignIn />} path="/signUp" />
        <Route element={<ForgotPass />} path="/recoverPass" />
      </Routes>
      <div className="LoginPage__btns">
        <button
          type="button"
          className="LoginPage__privacy"
          onClick={() => navigate('/terms')}
        >
          Terms and conditions
        </button>
        <button
          type="button"
          className="LoginPage__privacy"
          onClick={() => navigate('/privacy')}
        >
          Privacy policy
        </button>
      </div>
    </div>
  );
}
