import axios from 'axios';
import { SendTemplateObj } from '../types/templates';

const API = process.env.REACT_APP_API;
export const fetchDeleteArtifact = (id: number) =>
  axios.delete(`${API}/artifact/${id}`, {
    withCredentials: true,
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  });

export const getTemplateTypes = () =>
  axios.get(`${API}/artifact/templates/types`, {
    withCredentials: true,
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  });

export const getTemplates = () =>
  axios.get(`${API}/artifact/templates`, {
    withCredentials: true,
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  });

export const sendTemplate = (artifacts: SendTemplateObj[]) =>
  axios.post(
    `${API}/artifact`,
    { artifacts },
    {
      withCredentials: true,
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      },
    },
  );

export const fetchUploadCSV = () =>
  axios.get(`${API}/upload`, {
    withCredentials: true,
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  });

export const sendTemplateFromFile = (data: File) => {
  const formData = new FormData();
  formData.append('file', data);

  return axios.post(`${API}/upload`, formData, {
    withCredentials: true,
    headers: {
      'Content-Type': 'multipart/form-data',
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  });
};

export const getArtifacts = (data: {
  skip: number;
  search: string;
  filter: string;
}) =>
  axios.get(
    `${API}/artifact?take=20&skip=${data.skip}&search=${
      data?.search || ''
    }&filter=${data?.filter || ''}`,
    {
      withCredentials: true,
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      },
    },
  );

export const getArtifact = (id: number) =>
  axios.get(`${API}/artifact/${id}`, {
    withCredentials: true,
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  });

export const getLazyArtifact = (offset: number) =>
  axios.get(`${API}/artifact/templates-lazy?offset=${offset}`, {
    withCredentials: true,
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  });

export const getQuestions = (id: number) =>
  axios.get(`${API}/artifact/varibales?id=${id}`, {
    withCredentials: true,
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  });

export const removeArtifact = (id: number) =>
  axios.delete(`${API}/artifact/${id}`, {
    withCredentials: true,
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  });
