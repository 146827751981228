import React from 'react';
import './SecondaryButton.scss';

interface SecondaryButtonProps {
  onClick: () => void;
  text: string;
  disabled?: boolean;
  iconPosition?: 'left' | 'right';
  icon?: JSX.Element;

  position: 'left' | 'right' | 'center';
}

function SecondaryButton({
  onClick,
  text,
  disabled,
  iconPosition,
  icon,
  position,
}: SecondaryButtonProps) {
  return (
    <button
      className={`secondary-btn ${position}`}
      type="button"
      disabled={disabled}
      onClick={onClick}
    >
      {iconPosition === 'left' && icon}
      {text}
      {iconPosition === 'right' && icon}
    </button>
  );
}

SecondaryButton.defaultProps = {
  disabled: false,
  iconPosition: null,
  icon: null,
};

export default SecondaryButton;
