import React, { MutableRefObject } from 'react';
import * as xlsx from 'xlsx';
import PrimaryButton from '../../Buttons/PrimaryButton';
import { ReactComponent as UploadedIcon } from '../../../images/utils-icons/check-circle-2.svg';
import './FileInput.scss';

interface FileInputProps {
  title: string;
  onUpload: (instructions: string[]) => void;
  onOpen: () => void;
}
function FileInput({ title, onUpload, onOpen: handleClick }: FileInputProps) {
  // const [file, setFile] = React.useState<File | null>(null);
  const [isUploaded, setIsUploaded] = React.useState(false);

  const input =
    React.useRef<HTMLInputElement>() as MutableRefObject<HTMLInputElement>;

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    if (file) {
      const data = await file.arrayBuffer();
      const wb = xlsx.read(data);
      const entries = Object.entries(wb.Sheets[wb.SheetNames[0]]);
      const instructions = entries
        .map((entry, index) => {
          if (index === 10) return null;
          if (entry[0].match(/A\d+/)) {
            return entry[1]?.v;
          }
          return null;
        })
        .filter(Boolean);
      setIsUploaded(true);
      onUpload(instructions);
    }
  };
  return (
    <div className="file-input">
      <h2 className="file-input__title">{title}</h2>
      <div className="file-input__inner">
        {!isUploaded ? (
          <label htmlFor="input-file" className="file-input__upload">
            <span className="file-input__subtitle">
              Choose file from Computer
            </span>
            <PrimaryButton
              text="Upload file"
              onClick={() => {
                input.current.click();
              }}
            />
            <input
              className="file-input__input"
              ref={input}
              id="input-file"
              type="file"
              accept=".xlsx"
              onChange={handleChange}
            />
          </label>
        ) : (
          <button
            type="button"
            className="file-input__uploaded"
            onClick={handleClick}
          >
            <UploadedIcon />
            <span>Upload Successful!</span>
          </button>
        )}
      </div>
    </div>
  );
}

export default FileInput;
