/* eslint-disable react/no-children-prop */
import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { deleteArtifact } from '../../../redux/slices/templateSlice';

import trash from '../../../images/dashbord-icons/trash-black.svg';
import MainBtn from '../../Buttons/MainBtn';
import saveCSV from '../../../utils/saveCSV';
import './DashboardCreatedCard.scss';
import CopyButton from '../../Buttons/CopyButton';

type Props = {
  // title: string;
  text: string;
  id: number;
};

export default function DashboardCreatedCard({ text, id }: Props) {
  const dispatch = useDispatch<AppDispatch>();
  const handleDelete = () => {
    dispatch(deleteArtifact(id));
  };

  return (
    <div className="DashboardCreatedCard">
      <div className="DashboardCreatedCard__main">
        <ReactMarkdown children={text} remarkPlugins={[remarkGfm]} />
      </div>
      <div className="DashboardCreatedCard__btns">
        <CopyButton
          onCopy={() => navigator.clipboard.writeText(text?.trim())}
        />
        <button type="button" onClick={() => saveCSV(text?.trim())}>
          <MainBtn text="Export" type="small" disable={false} />
        </button>
        <button
          className="DashboardCreatedCard__btn"
          type="button"
          onClick={handleDelete}
        >
          <img src={trash} alt="trash" />
        </button>
      </div>
    </div>
  );
}
