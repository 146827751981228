/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { AppDispatch, RootState } from '../../redux/store';
import { changeGPTModel } from '../../redux/slices/authSlice';
import { setCoach } from '../../redux/slices/chatSlice';
import './SelectModelDropdown.scss';

type model = {model: string, name: string, title?: string}
type Props = {
  type: 'model' | 'role';
};

const options: model[] = [
  {model: 'gpt-3.5-turbo', name: 'GPT-3.5'},
  {model: 'gpt-4', name: 'GPT-4'},
  {model: 'gpt-4-1106-preview', name: 'GPT-4 Turbo'},
  {model: 'gpt-4o', name: 'GPT-4o'},
  {model: 'claude-3-opus-20240229', name: 'Claude 3 Opus'},
  {model: 'claude-3-sonnet-20240229', name: 'Claude 3 Sonnet'},
  {model: 'claude-2.1', name: 'Claude 2.1'},
  {model: 'claude-instant-1.2', name: 'Claude Instant'},
  {model: 'gemini-1.0-pro-latest', name: 'Gemini 1.0 Pro'},
];

export default function SelectModelDropdown({ type }: Props) {
  const dispatch = useDispatch<AppDispatch>();
  const [isOpen, setIsOpen] = useState(false);
  const [selectOption, setSelectOption] = useState('');

  // eslint-disable-next-line react-redux/useSelector-prefer-selectors
  const { userInfo } = useSelector((state: RootState) => state.auth);
  // eslint-disable-next-line react-redux/useSelector-prefer-selectors
  const { coachList, coachRole } = useSelector(
    (state: RootState) => state.chat,
  );



  useEffect(() => {
    if (type === 'model') {
      if (userInfo?.model) {
        const md = options.find((opt) => opt.model === userInfo?.model) || options[0];
        setSelectOption(md.name);
      }
    } else {
      setSelectOption(coachRole?.title || coachList[0]?.title);
    }
  }, [coachRole]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const hendleSelectOption = (opt: model) => {
    if (type === 'model') {
      setSelectOption(opt.name);
      if (opt.model !== userInfo?.model) {
        dispatch(
          changeGPTModel({
            model: opt.model,
          }),
        );
      }
    } else {
      setSelectOption(opt?.title || '');
      dispatch(setCoach(opt));
    }

    setIsOpen(false);
  };

  return (
    <div className="SelectModelDropdown">
      {type === 'model' ? (
        <button
          type="button"
          className="SelectModelDropdown__button"
          onClick={toggleDropdown}
        >
          {selectOption}
          {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </button>
      ) : (
        <button
          type="button"
          className="SelectModelDropdown__button"
          onClick={toggleDropdown}
        >
          {selectOption}
          {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </button>
      )}
      {isOpen && (
        <div className="SelectModelDropdown__list">
          {type === 'model' ? (
            <div className="SelectModelDropdown__list-wrapper">
              {options.map((option: model) => (
                <button
                  type="button"
                  key={option.model || option?.title}
                  className="SelectModelDropdown__item"
                  onClick={() => hendleSelectOption(option)}
                >
                  {option.name}
                </button>
              ))}
            </div>
          ) : (
            <div className="SelectModelDropdown__list-wrapper">
              {coachList.map((option: any) => (
                <button
                  type="button"
                  key={option.id}
                  className="SelectModelDropdown__item SelectModelDropdown__item--role"
                  onClick={() => hendleSelectOption(option)}
                >
                  {option.title}
                </button>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
