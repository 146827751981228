import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ThreeDots } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';

import {
  getAllTemplates,
  startFetchingAgain,
} from '../../../redux/slices/templateSlice';
import { AppDispatch, RootState } from '../../../redux/store';
import { Template } from '../../../types/templates';
import TemplateCard from '../../Cards/TemplateCard';
import MainHeader from '../../MainHeader';
import './TemplatesSection.scss';

export default function TemplatesSection() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const scrollableDivRef = useRef<any>(null);

  const [offset, setOffset] = useState(0);

  // eslint-disable-next-line react-redux/useSelector-prefer-selectors
  const { templates, loading, stopFetchTemplates } = useSelector(
    (state: RootState) => state.templates,
  );

  useEffect(() => {
    return () => {
      dispatch(startFetchingAgain());
    };
  }, []);

  const handleScroll = () => {
    const div = scrollableDivRef.current;
    if (div) {
      if (div.scrollHeight - div.scrollTop === div.clientHeight) {
        setOffset(offset + 2);
      }
    }
  };

  useEffect(() => {
    if (!stopFetchTemplates) {
      dispatch(getAllTemplates(offset));
    }
  }, [dispatch, offset, stopFetchTemplates]);

  return (
    <div className="TemplatesSection">
      <div className="TemplatesSection__header--padding">
        <MainHeader text="Templates" />
      </div>
      <div
        ref={scrollableDivRef}
        className="TemplatesSection__main"
        onScroll={handleScroll}
      >
        {templates?.map((temp: any) => (
          <div key={temp?.id}>
            <h2 className="TemplatesSection__title">{temp?.name}</h2>
            <div className="TemplatesSection__list">
              {temp?.templates?.map((item: Template) => (
                <button
                  key={item?.id}
                  type="button"
                  onClick={() => {
                    navigate(
                      `/explain?categoryId=${temp?.id}&templateId=${item?.id}`,
                      {
                        replace: true,
                      },
                    );
                  }}
                >
                  <TemplateCard
                    title={item?.title}
                    icon={item?.image ? item?.image[0]?.url : ''}
                    text={item?.description}
                  />
                </button>
              ))}
            </div>
          </div>
        ))}
        {loading && (
          <div className="TemplatesSection__spinner-container">
            <ThreeDots
              height="50"
              width="60"
              radius="9"
              color="#1363DF"
              ariaLabel="three-dots-loading"
              visible
            />
          </div>
        )}
      </div>
    </div>
  );
}
