import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { changeModel, getPlan, getUser, Login, LogOut, openBillingPortal, resetPassAuth, selectPlan, signIn } from '../../api/auth';
import { Auth, UserInfo } from '../../types/auth';

export interface AuthState {
  loading: boolean;
  userInfo: UserInfo | null;
  planInfo: any,
  dashLoading: boolean;
  pricingLoader: boolean;
  errorMessageSignUp: string;
  errorMessage: string;
  darkMode: boolean;
}

const initialState: AuthState = {
  loading: false,
  userInfo: null,
  planInfo: null,
  dashLoading: false,
  pricingLoader: false,
  errorMessageSignUp: '',
  errorMessage: '',
  darkMode: false,
};

export const signInUser = createAsyncThunk(
  'auth/signInUser',
  async (data: Auth, { rejectWithValue, dispatch }) => {
    dispatch(startLoading());
    try {
      await signIn(data);
      dispatch(endLoading());
      dispatch(startPriceLoading());
    } catch (error: any) {
      dispatch(endLoading());
      dispatch(addErrorMessageSignUp(error?.response?.data?.message));
    }
  },
);

export const LoginUser = createAsyncThunk(
  'auth/LoginUser',
  async (data: Auth, { rejectWithValue, dispatch }) => {
    dispatch(startLoading());
    try {
      await Login(data);
      dispatch(startPriceLoading());
    } catch (error: any) {
      dispatch(endLoading());
      dispatch(addError(error?.response?.data?.message));
    }
  },
);

export const resetPassUser = createAsyncThunk(
  'auth/resetPassUser',
  async (email: string) => {
    try {
      await resetPassAuth(email);
    } catch (error) {}
  },
);

export const LogOutUser = createAsyncThunk(
  'auth/LogOutUser',
  async (_, { rejectWithValue, dispatch }) => {
    dispatch(startPriceLoading());
    try {
      await LogOut();
      dispatch(endPriceLoading());
    } catch (error) {
      dispatch(endPriceLoading());
    }
  },
);

export const getUserInfo = createAsyncThunk(
  'auth/getUserInfo',
  async (_, { rejectWithValue, dispatch }) => {
    dispatch(startDashLoading());
    try {
      const res = await getUser();
      dispatch(endDashLoading());
      return res?.data;
    } catch (error) {
      dispatch(endDashLoading());
    }
  },
);

export const checkPlan = createAsyncThunk(
  'auth/checkPlan',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const res = await getPlan();

      if (res.data.success) {
        return res?.data;
      } else {
        window.location.href = process.env.REACT_APP_API_PRICE || "";
      }
    } catch (error) {
      dispatch(endPriceLoading());
    }
  },
);

export const redirectToStripe = createAsyncThunk(
  'auth/redirectToStripe',
  async (body: any, { rejectWithValue, dispatch }) => {
    dispatch(startPriceLoading());
    try {
      const {id, trial} = body;
      const res = await selectPlan(id, trial);
      window.location.href = res.data.url;
    } catch (error) {
      dispatch(endPriceLoading());
    }
  },
);

export const goToBillingPortal = createAsyncThunk(
  'auth/goToBillingPortal',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const res = await openBillingPortal();
      window.open(res.data.url, '_blank');
    } catch (error) {
      dispatch(endPriceLoading());
    }
  },
);

export const changeGPTModel = createAsyncThunk(
  'auth/changeGPTModel',
  async (data: {model: string}, { rejectWithValue, dispatch }) => {
    try {
      await changeModel(data);
      return data.model;
    } catch (error) {
      dispatch(endPriceLoading());
    }
  },
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearAuth: (state) => {
      state.loading = false
      state.userInfo = null
      state.dashLoading = false
      state.errorMessageSignUp = ''
      state.errorMessage = ''
    },
    startLoading: (state) => {
      state.loading = true;
    },
    endLoading: (state) => {
      state.loading = false;
    },
    startDashLoading: (state) => {
      state.dashLoading = true;
    },
    endDashLoading: (state) => {
      state.dashLoading = false;
    },
    startPriceLoading: (state) => {
      state.pricingLoader = true;
    },
    endPriceLoading: (state) => {
      state.pricingLoader = false;
    },
    removeError: (state) => {
      state.errorMessage = '';
    },
    addError: (state, action) => {
      state.errorMessage = action.payload;
    },
    removeErrorMessageSignUp: (state) => {
      state.errorMessageSignUp = '';
    },
    addErrorMessageSignUp: (state, action) => {
      state.errorMessageSignUp = action.payload;
    },
    setDarkMode: (state, action) => {
      state.darkMode = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(getUserInfo.fulfilled, (state, action: any) => {
      state.userInfo = action.payload;
    })
    .addCase(checkPlan.fulfilled, (state, action: any) => {
      state.planInfo = action.payload;
    })
    .addCase(changeGPTModel.fulfilled, (state, action: any) => {
      state.userInfo = {
        ...state.userInfo,
        model: action.payload
      }
    })
    .addCase(LogOutUser.fulfilled, (state) => {
      state.loading = false;
      state.userInfo = null;
      state.planInfo = null;
      state.dashLoading = false;
      state.pricingLoader = false;
      state.errorMessageSignUp = '';
      state.errorMessage = '';
    })
  },
});

export const {
  startLoading,
  removeError,
  endLoading,
  startDashLoading,
  endDashLoading,
  addError,
  removeErrorMessageSignUp,
  addErrorMessageSignUp,
  clearAuth,
  startPriceLoading,
  endPriceLoading,
  setDarkMode,
} = authSlice.actions;

export default authSlice.reducer;
