import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import templatesReducer from './slices/templateSlice';
import authReducer from './slices/authSlice';
import inboxReducer from './slices/inboxSlice';
import chatReducer from './slices/chatSlice';

export const rootReducer = combineReducers({
  auth: authReducer,
  templates: persistReducer(
    {
      key: 'templates',
      storage,
      blacklist: ['templates'],
    },
    templatesReducer,
  ),
  inbox: persistReducer(
    {
      key: 'inbox',
      storage,
      blacklist: ['notifications'],
    },
    inboxReducer,
  ),
  chat: persistReducer(
    {
      key: 'chat',
      storage,
      blacklist: ['error', 'writing'],
    },
    chatReducer,
  ),
});

const persistConfig = {
  key: 'stories',
  storage,
  blacklist: ['chat', 'inbox'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
