import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import dash from '../../../images/sidebar-icons/dashboard.svg';
import template from '../../../images/sidebar-icons/template.svg';
import coach from '../../../images/sidebar-icons/coach.svg';
import inbox from '../../../images/sidebar-icons/inbox.svg';
import notification from '../../../images/sidebar-icons/notification.svg';
import help from '../../../images/sidebar-icons/help.svg';
import { Message } from '../../../types/inbox';

export default function SidebarList() {
  const dotRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const pathName = window.location.pathname;

  // eslint-disable-next-line react-redux/useSelector-prefer-selectors
  const { notifications } = useSelector((state: RootState) => state.inbox);

  const navigateTo = (path: string) => {
    navigate(path);
  };

  return (
    <div className="Sidebar__list">
      <button
        type="button"
        className={`Sidebar__list-link ${
          pathName === '/dashboard' && 'Sidebar__list-link--active'
        }`}
        onClick={() => navigateTo('/dashboard')}
      >
        <img src={dash} alt="dashboard" className="Sidebar__list-link-icon" />
        Dashboard
      </button>
      <button
        type="button"
        className={`Sidebar__list-link ${
          pathName === '/templates' && 'Sidebar__list-link--active'
        }`}
        onClick={() => navigateTo('/templates')}
      >
        <img
          src={template}
          alt="templates"
          className="Sidebar__list-link-icon"
        />
        Templates
      </button>
      <button
        type="button"
        className={`Sidebar__list-link ${
          pathName === '/coach' && 'Sidebar__list-link--active'
        }`}
        onClick={() => navigateTo('/coach')}
      >
        <img src={coach} alt="coach" className="Sidebar__list-link-icon" />
        AI Agile Coach
      </button>
      <div className="Sidebar__border" />
      <button
        type="button"
        className={`Sidebar__list-link ${
          pathName === '/inbox' && 'Sidebar__list-link--active'
        }`}
        onClick={() => {
          if (dotRef.current) {
            dotRef.current.remove();
          }
          navigateTo('/inbox');
        }}
      >
        <img src={inbox} alt="inbox" className="Sidebar__list-link-icon" />
        {notifications?.some((a: Message) => !a.isRead) && (
          <div ref={dotRef} className="Sidebar__dot" />
        )}
        Inbox
      </button>
      <button
        type="button"
        className={`Sidebar__list-link ${
          pathName === '/balance' && 'Sidebar__list-link--active'
        }`}
        onClick={() => navigateTo('/balance')}
      >
        <img
          src={notification}
          alt="balance"
          className="Sidebar__list-link-icon"
        />
        Balance
      </button>
      <button
        type="button"
        className={`Sidebar__list-link ${
          pathName === '/help' && 'Sidebar__list-link--active'
        }`}
        onClick={() => navigateTo('/help')}
      >
        <img src={help} alt="help" className="Sidebar__list-link-icon" />
        Help
      </button>
    </div>
  );
}
