import clock from '../images/dashbord-icons/clock.svg';
import link from '../images/dashbord-icons/external-link.svg';
import files from '../images/dashbord-icons/files.svg';

export interface TotalInfo {
  title: string;
  icon: string;
  info: number;
}

export const arrTotalInfo: TotalInfo[] = [
  {
    title: 'Total Outputs Generated',
    icon: link,
    info: 0,
  },
  {
    title: 'Total Files Uploaded',
    icon: files,
    info: 0,
  },
  {
    title: 'Total Saved Time, h',
    icon: clock,
    info: 0,
  },
];
