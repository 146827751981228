import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from '../../redux/store';
import ExplainPage from '../ExplainPage';
import ScratchPage from '../ScratchPage';
import CoachSection from '../Sections/CoachSection';
import DashboardSection from '../Sections/DashboardSection';
import HelpSection from '../Sections/HelpSection';
import InboxSection from '../Sections/InboxSection';
import TemplatesSection from '../Sections/TemplatesSection';
import ResultPage from '../ResultPage';
import Sidebar from '../Sidebar';
import UploadPage from '../UploadPage';
import Loader from '../Loader';
import BalanceSection from '../Sections/BalanceSection';
import {
  checkPlan,
  endPriceLoading,
  getUserInfo,
} from '../../redux/slices/authSlice';

import './Main.scss';

export default function Main() {
  const dispatch = useDispatch<AppDispatch>();
  // const navigate = useNavigate();
  // eslint-disable-next-line react-redux/useSelector-prefer-selectors
  const { pricingLoader, planInfo } = useSelector(
    (state: RootState) => state.auth,
  );

  useEffect(() => {
    dispatch(getUserInfo());
    const foo = async () => {
      if (!planInfo?.success) {
        dispatch(checkPlan());
      } else {
        dispatch(endPriceLoading());
      }
    };

    foo();
  }, [planInfo?.success]);

  return (
    <div className="Main">
      {pricingLoader ? (
        <div className="Main__loader">
          <Loader />
        </div>
      ) : (
        <>
          <Sidebar />
          <main className="Main__section">
            <Routes>
              <Route path="*" element={<Navigate to="/dashboard" />} />
              <Route path="dashboard" element={<DashboardSection />} />
              <Route path="templates" element={<TemplatesSection />} />
              <Route path="coach" element={<CoachSection />} />
              <Route path="inbox" element={<InboxSection />} />
              <Route path="help" element={<HelpSection />} />
              <Route path="explain" element={<ExplainPage />} />
              <Route path="create" element={<ScratchPage />} />
              <Route path="result" element={<ResultPage />} />
              <Route path="upload" element={<UploadPage />} />
              <Route path="balance" element={<BalanceSection />} />
            </Routes>
          </main>
        </>
      )}
    </div>
  );
}
