import axios from 'axios';
import { Auth, RecoverPass } from '../types/auth';

const API = process.env.REACT_APP_API;
export const resetPassAuth = (email: string) =>
  axios.get(`${API}/auth/reset-password?email=${email}`, {
    withCredentials: true,
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  });

export const signIn = (data: Auth) =>
  axios.post(`${API}/auth/sign-up`, data, {
    withCredentials: true,
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  });

export const Login = (data: Auth) =>
  axios.post(`${API}/auth/sign-in`, data, {
    withCredentials: true,
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  });

export const RecoverPassApi = (data: RecoverPass) =>
  axios.post(`${API}/auth/recovery-password`, data, {
    withCredentials: true,
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  });

export const LogOut = () =>
  axios.get(`${API}/auth/logout`, {
    withCredentials: true,
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  });

export const getUser = () =>
  axios.get(`${API}/user/profile`, {
    withCredentials: true,
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  });

export const getPlan = () =>
  axios.get(`${API}/billing/check`, {
    withCredentials: true,
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  });

export const openBillingPortal = () =>
  axios.get(`${API}/billing/manage`, {
    withCredentials: true,
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  });

export const selectPlan = (id: string, trial: number) =>
  axios.get(`${API}/billing/checkout/${id}?trial=${trial}`, {
    withCredentials: true,
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  });

export const changeModel = (data: { model: string }) =>
  axios.post(`${API}/user/change-model`, data, {
    withCredentials: true,
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  });
