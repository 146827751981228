import React from 'react';
import './MainBtn.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

type Props = {
  text: string;
  type: string;
  disable: boolean;
};

export default function MainBtn({ text, type, disable }: Props) {
  // eslint-disable-next-line react-redux/useSelector-prefer-selectors
  const { darkMode } = useSelector((state: RootState) => state.auth);
  return (
    <div className={`MainBtn ${type} ${disable && 'disable'}`}>{text}</div>
  );
}
