/* eslint no-use-before-define: 0 */
import React from 'react';
import { useNavigate } from 'react-router-dom';

import cross from '../../images/utils-icons/x-circle.svg';
import './PrivacyPage.scss';

type Props = {
  type: 'privacy' | 'terms';
};

export default function PrivacyPage({ type }: Props) {
  const navigate = useNavigate();
  return (
    <div className="PrivacyPage">
      <div className="PrivacyPage__box">
        <div className="PrivacyPage__header">
          <h2 className="PrivacyPage__title">
            {type === 'terms' ? 'Terms and Conditions' : 'Privacy Policy'}
          </h2>
          <button
            type="button"
            className="PrivacyPage__btn"
            onClick={() => navigate(-1)}
          >
            <img src={cross} alt="cross" />
          </button>
        </div>
        <div className="PrivacyPage__info">
          <p>
            This privacy policy discloses the privacy practices for Nesmo
            (the “Application”). The Application is operated by Boostaro LLC.
            (“we” or “us” or “our”).
          </p>
          <h5>Information Collection And Use</h5>
          <p>
            While using our Application, we may ask you to provide us with
            certain personally identifiable information that can be used to
            contact or identify you. Personally identifiable information may
            include, but is not limited to, your name, email address, postal
            address, phone number, etc. (“Personal Information”).
          </p>
          <p>
            We collect this information for the purpose of providing the
            Nesmo services to you, identifying and communicating with you,
            responding to your requests/inquiries, serving you better, and
            improving our Application.
          </p>
          <p>
            We may collect information about how you access and use this
            Application. We may collect information about the pages that you
            visit, access times, frequency, duration of visits and use of
            various Application features. We may use third-party analytics tools
            like Google Analytics to help analyze how users use the Application.
            The information generated by the cookies or other analytics
            technologies about your use of the Application (the “Analytics
            Information”) is transmitted to and stored by these service
            providers on our behalf. The analytics providers use the Analytics
            Information to evaluate your use of the Application, compiling
            reports on website activity and providing other services relating to
            website activity and internet usage.
          </p>
          <h5>Information Sharing and Disclosure</h5>
          <p>
            We do not sell, trade, or rent your personal information to others.
            We may share generic aggregated demographic information not linked
            to any personal identification information regarding visitors and
            users with our business partners, trusted affiliates and advertisers
            for the purposes outlined above.
          </p>
          <p>
            We may disclose your Personal Information and Analytics Information:
          </p>
          <ul>
            <li>
              As required by law, such as to comply with a subpoena, or similar
              legal process
            </li>
            <li>
              When we believe in good faith that disclosure is necessary to
              protect our rights, protect your safety or the safety of others,
              investigate fraud, or respond to a government request
            </li>
            <li>
              With trusted service providers who work on our behalf, do not have
              an independent use of the information we disclose to them, and
              have agreed to adhere to the rules set forth in this privacy
              statement.
            </li>
            <li>
              If Nesmo, Inc. is involved in a merger, acquisition, or sale of
              all or a portion of its assets. You will be notified via email
              and/or a prominent notice on our website of any change in
              ownership or uses of your personal information, as well as any
              choices you may have regarding your personal information.
            </li>
            <li>
              To advertisers and third party marketing partners who may use
              aggregate or de-identified information to serve targeted
              advertisements that they believe will be of interest to you. You
              may opt-out of many of these advertisers&apos; and partners&apos;
              programs by adjusting your advertising preferences in your account
              settings on the service.
            </li>
          </ul>
          <h5>Links To Other Sites</h5>
          <p>
            Our Application may contain links to other sites that are not
            operated by us. If you click on a third party link, you will be
            directed to that third party&apos;s site. We strongly advise you to
            review the privacy policy of every site you visit. We have no
            control over and assume no responsibility for the content, privacy
            policies or practices of any third party sites or services.
          </p>
          <h5>Changes To This Privacy Policy</h5>
          <p>
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>
          <p>
            Contact Us If you have any questions about this Privacy Policy or
            our data collection, use and storage practices, please contact us at
            <a href="mailto:support@agilegpt.com">support@agilegpt.com</a>.
          </p>
        </div>
      </div>
    </div>
  );
}
