import axios from 'axios';

const API = process.env.REACT_APP_API;
export const getNotifications = (skip: number) =>
  axios.get(`${API}/notification?take=10&skip=${skip || 0}`, {
    withCredentials: true,
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  });

export const getRead = () =>
  axios.get(`${API}/notification/read`, {
    withCredentials: true,
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  });

export const deleteNotification = (id: number) =>
  axios.delete(`${API}/notification/${id}`, {
    withCredentials: true,
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  });

export const getHelp = () =>
  axios.get(`${API}/qa`, {
    withCredentials: true,
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  });
