import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from '../../../redux/store';
import { checkPlan, startPriceLoading } from '../../../redux/slices/authSlice';
import google from '../../../images/utils-icons/GooglBtn.svg';

import './GoogleBtn.scss';

export default function GoogleBtn() {
  const dispatch = useDispatch<AppDispatch>();
  // eslint-disable-next-line react-redux/useSelector-prefer-selectors
  const { darkMode } = useSelector((state: RootState) => state.auth);
  return (
    <button
      type="button"
      className="GoogleBtn"
      onClick={() => {
        window.location.href = process.env.REACT_APP_API_AUTH || '';
        // dispatch(startPriceLoading());
        // dispatch(checkPlan());
      }}
    >
      <img src={google} alt="google" className="GoogleBtn__img" />
      Sign in with Google
    </button>
  );
}
