import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Loader from '../Loader/Loader';
import PrimaryButton from '../Buttons/PrimaryButton';
import {
  OptionalTemplateField,
  RequiredTemplateField,
  SendVariable,
  Template,
} from '../../types/templates';
import { AppDispatch, RootState } from '../../redux/store';
import './UploadPage.scss';
import OptionalField from '../OptionalField';
import FileInput from './FileInput';
import { createArtifacts, uploadCSV } from '../../redux/slices/templateSlice';

function UploadPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [searchParams] = useSearchParams() || '';
  const type = searchParams.get('type') || '';
  // eslint-disable-next-line react-redux/useSelector-prefer-selectors
  const { loading, templates } = useSelector(
    (state: RootState) => state.templates,
  );

  const [template, setTemplate] = useState<Template | null>(null);
  const [requiredFields, setRequiredFields] = useState<RequiredTemplateField[]>(
    [],
  );
  const [optionalFields, setOptionalFields] = useState<OptionalTemplateField[]>(
    [],
  );

  const [isPreview, setPreview] = useState<boolean>(false);

  const [artifactOptions, setArtifactOptions] = useState<SendVariable[]>([]);
  const [instructions, setInstructions] = useState<string[]>([]);

  // Set current template
  useEffect(() => {
    if (templates) {
      const currentTemplate = templates.find((temp) => temp.type === type);
      if (currentTemplate) setTemplate(currentTemplate);
    }
  }, [templates, type]);

  useEffect(() => {
    if (template) {
      const reqFields = template.variables
        .filter((item) => item.required)
        .map(
          (field): RequiredTemplateField => ({
            id: field.id,
            description: field.description,
            name: field.name,
            placeholder: field.placeholder,
            value: field.default,
            required: field.required,
          }),
        );
      const optFields = template.variables
        .filter((item) => !item.required)
        .map(
          (field): OptionalTemplateField => ({
            id: field.id,
            name: field.name,
            placeholder: field.placeholder,
            value: field.default,
            input: field.input,
            description: field.description,
            title: field.description,
            select: field.select,
            min: field.min,
            max: field.max,
            required: field.required,
          }),
        );
      setRequiredFields(reqFields);
      setOptionalFields(optFields);
      setArtifactOptions(optFields);
    }
  }, [template]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const artifactsToRequest = instructions.map((instruction) => ({
      name: 'Any name',
      type,
      variables: [
        ...requiredFields
          .map((field) => {
            if (field.value === '') return null;
            return {
              name: field.name,
              value: instruction,
            };
          })
          .filter(Boolean),
        ...artifactOptions.map((option) => ({
          name: option.name,
          value: option.value,
        })),
      ] as { name: string; value: SendVariable['value'] }[],
    }));

    dispatch(uploadCSV());
    await dispatch(createArtifacts(artifactsToRequest));
    searchParams.set('type', `${type}`);
    navigate(`/result?${searchParams.toString()}`, {
      replace: true,
    });
  };

  return (
    <div className="upload-page">
      {/* eslint-disable-next-line no-nested-ternary */}
      {loading ? (
        <Loader />
      ) : (
        <>
          {isPreview && (
            <div className="upload-page__preview">
              {instructions.map((instruction: string) => (
                <p>{instruction}</p>
              ))}
              <PrimaryButton
                text="Back"
                onClick={() => setPreview(false)}
                style={{ alignSelf: 'start', marginTop: '40px' }}
              />
            </div>
          )}
          {
            // Change header to component
          }
          <div className="scratch-page__header">
            <h2 className="scratch-page__title">{template?.title}</h2>
            <div className="scratch-page__btn-group">
              {/* <PrimaryButton
                text="Upload XLSX"
                onClick={() =>
                  navigate(`/upload?${searchParams.toString()}`, {
                    replace: true,
                  })
                }
                disabled
              /> */}
              <PrimaryButton
                text="Create from Scratch"
                onClick={() =>
                  navigate(`/create?${searchParams.toString()}`, {
                    replace: true,
                  })
                }
              />
            </div>
          </div>
          <div className="upload-page__content">
            <FileInput
              title={template?.csv_tooltip || ''}
              onUpload={setInstructions}
              onOpen={() => setPreview(true)}
            />
          </div>
          {
            // Change to component
          }
          <div className="scratch-page__optional-fields">
            {optionalFields.map((item) => {
              return (
                <OptionalField
                  key={Math.floor(Math.random() * 100000)}
                  input={item.input}
                  title={item.title}
                  default={item.value}
                  select={item.select}
                  name={item.name}
                  min={item.min}
                  max={item.max}
                  onChange={(name: string, value: SendVariable['value']) => {
                    setArtifactOptions((state) => {
                      const currentField = state.find(
                        (variable) => variable.name === name,
                      );
                      if (currentField) currentField.value = value;
                      return state;
                    });
                  }}
                />
              );
            })}
          </div>
          <PrimaryButton
            text="Submit"
            style={{ alignSelf: 'center', marginTop: '20px' }}
            onClick={handleSubmit}
          />
        </>
      )}
    </div>
  );
}

export default UploadPage;
