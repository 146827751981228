import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import files from '../../../images/dashbord-icons/files.svg';
import copied from '../../../images/dashbord-icons/copied.png';
import './CopyButton.scss';
import { RootState } from '../../../redux/store';

export default function CopyButton({ onCopy }: { onCopy: () => void }) {
  const [isCopied, setIsCopied] = React.useState(false);
  // eslint-disable-next-line react-redux/useSelector-prefer-selectors
  const { darkMode } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (!isCopied) return undefined;
    const timeout = setTimeout(() => setIsCopied(false), 2000);
    return () => clearTimeout(timeout);
  });

  return (
    <button
      className="copy-btn"
      type="button"
      style={{ transform: 'translateY(3px)' }}
      onClick={() => {
        setIsCopied(true);
        onCopy();
      }}
    >
      <img src={isCopied ? copied : files} alt="file" />
    </button>
  );
}
