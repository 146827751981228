import React from 'react';
import './LoginPageBtn.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

type Props = {
  text: string;
};

export default function LoginPageBtn({ text }: Props) {
  return <div className="LoginPageBtn">{text}</div>;
}
