import React from 'react';
import { ReactComponent as FilterIcon } from '../../images/dashbord-icons/filter.svg';
import './Filter.scss';

interface FilterProps {
  items: any;
  setFilter: (arg: string[]) => void;
}
function Filter({ items, setFilter }: FilterProps) {
  const [isShow, setIsShow] = React.useState(false);
  const [selectedCount, setSelectedCount] = React.useState(0);
  const [selectedItems, setSelectedItems] = React.useState<string[]>([]);

  React.useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      if (target.closest('.filter')) {
        return;
      }
      setIsShow(false);
    };
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  React.useEffect(() => {
    setSelectedCount(selectedItems.length);
    setFilter(selectedItems);
  }, [selectedItems]);

  return (
    <div className="filter">
      <button
        type="button"
        className="filter__action-button"
        onClick={() => {
          setIsShow(!isShow);
        }}
      >
        <FilterIcon />
        <span>Filter ({selectedCount})</span>
      </button>
      {isShow && (
        <div className="filter__list">
          {items.map((item: any) => (
            <button
              key={item.type}
              className={`filter__item ${
                selectedItems.includes(item.type) && 'filter__item--selected'
              }`}
              type="button"
              onClick={() => {
                setSelectedItems((state) => {
                  if (state.includes(item.type)) {
                    return state.filter((it) => it !== item.type);
                  }
                  return [...state, item.type];
                });
              }}
            >
              {item.type}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}

export default Filter;
