/* eslint-disable */
import React, { useState } from 'react';
import { setDarkMode } from '../../redux/slices/authSlice';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import { useDispatch } from 'react-redux';
import Switch from "react-switch";
import './DarkModeToggle.scss';

const DarkModeToggle: React.FC = () => {  
    const { darkMode } = useSelector( (state: RootState) => state.auth, );
    const dispatch = useDispatch<AppDispatch>();

    const handleToggle = () => {
        dispatch(setDarkMode(!darkMode));
    };

    return (
        <div className='toggle-switch'>
            <label htmlFor='darkModeToggle' className={darkMode ? 
                "toggle-switch-label toggle-switch-left unactive-dark" : 
                "toggle-switch-label toggle-switch-left active-light"}>Light</label>
            <Switch 
                onChange={handleToggle} 
                checked={darkMode} 
                checkedIcon={false} 
                uncheckedIcon={false} 
                height={23} 
                width={42} 
                handleDiameter={17} 
                offHandleColor='#FAFDFE'
                offColor='#99C2FF'
                onColor='#686868'
            />
            <label htmlFor='darkModeToggle' className={darkMode ? 
                "toggle-switch-label toggle-switch-right active-dark" : 
                "toggle-switch-label toggle-switch-right unactive-light"}>Dark</label>
        </div>
    );
};

export default DarkModeToggle;