import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  LoginUser,
  removeError,
  removeErrorMessageSignUp,
  resetPassUser,
  signInUser,
} from '../../../redux/slices/authSlice';
import { AppDispatch, RootState } from '../../../redux/store';

import validateInput from '../../../utils/Validation';
import LoginPageBtn from '../../Buttons/LoginPageBtn';
import AuthInput from './AuthInput';
import './LoginForm.scss';

type Props = {
  type: 'SIGN UP' | 'LOG IN' | 'RESET PASSWORD';
};

export default function LoginForm({ type }: Props) {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  // eslint-disable-next-line react-redux/useSelector-prefer-selectors
  const { darkMode } = useSelector((state: RootState) => state.auth);

  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
  });

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    password: '',
  });

  useEffect(() => {
    setTimeout(() => {
      dispatch(removeErrorMessageSignUp());
      dispatch(removeError());
    }, 3000);
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: '' });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const nameError = validateInput('name', formData.name);
    const emailError = validateInput('email', formData.email);
    const passwordError = validateInput('password', formData.password);

    setErrors({
      ...errors,
      name: nameError,
      email: emailError,
      password: passwordError,
    });

    const { email, password, name } = formData;

    if (
      (type === 'SIGN UP' &&
        !emailError &&
        !passwordError &&
        !nameError &&
        name &&
        password &&
        email) ||
      (type === 'LOG IN' &&
        !emailError &&
        !passwordError &&
        email &&
        password) ||
      (type === 'RESET PASSWORD' && email && !emailError)
    ) {
      switch (type) {
        case 'SIGN UP':
          await dispatch(signInUser(formData));
          navigate('/dashbord');
          break;
        case 'LOG IN':
          await dispatch(LoginUser({ email, password }));
          navigate('/dashbord');
          break;
        default:
          dispatch(resetPassUser(email));
          setShowModal(true);
          break;
      }
    }
  };

  const handleBlur = (name: string, value: string) => {
    setErrors({
      ...errors,
      [name]: validateInput(name, value),
    });
  };

  return (
    <>
      {!showModal && type === 'RESET PASSWORD' && (
        <h2 className="Login__title">Forgot your password?</h2>
      )}
      <form onSubmit={handleSubmit} className="LoginForm">
        {showModal ? (
          <div className="LoginForm__modal">
            <div className="LoginForm__modal-content">
              <h2 className="LoginForm__modal-title">Check your email.</h2>
              <p className="LoginForm__modal-text">
                We sent you a message with instructions.
              </p>
            </div>
          </div>
        ) : (
          <>
            {type === 'SIGN UP' && (
              <AuthInput
                label="Name"
                id="name"
                name="name"
                placeholder="Enter your name"
                value={formData.name}
                onChange={handleChange}
                onBlur={() => handleBlur('name', formData.name)}
                error={errors.name}
              />
            )}
            <AuthInput
              label="Email"
              id="email"
              name="email"
              placeholder="Enter your email"
              value={formData.email}
              onChange={handleChange}
              onBlur={() => handleBlur('email', formData.email)}
              error={errors.email}
            />
            {type !== 'RESET PASSWORD' && (
              <AuthInput
                label="Password"
                id="password"
                name="password"
                placeholder="Enter your password"
                value={formData.password}
                onChange={handleChange}
                onBlur={() => handleBlur('password', formData.password)}
                error={errors.password}
              />
            )}
            {type === 'LOG IN' && (
              <button
                type="button"
                onClick={() => navigate('/auth/recoverPass')}
                className="LoginForm__forgot"
              >
                Forgot your password?
              </button>
            )}
            <button type="submit" className="LoginForm__submit-btn">
              <LoginPageBtn text={type} />
            </button>
          </>
        )}
      </form>
    </>
  );
}
