import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../redux/store';
import MainHeader from '../../MainHeader';
import PrimaryButton from '../../Buttons/PrimaryButton';
import DashboardTotalInfoCard from '../../Cards/DashboardTotalInfoCard';
import { checkPlan, goToBillingPortal } from '../../../redux/slices/authSlice';

import './BalanceSection.scss';

export default function BalanceSection() {
  const dispatch = useDispatch<any>();

  // eslint-disable-next-line react-redux/useSelector-prefer-selectors
  const { planInfo } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    dispatch(checkPlan());
  }, []);

  const secToDate = (sec: string) => {
    const milliseconds = +sec * 1000;

    const dateObject = new Date(milliseconds);

    const day = dateObject.getDate().toString().padStart(2, '0');
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
    const year = dateObject.getFullYear();

    return `${day}/${month}/${year}`;
  };

  return (
    <div className="BalanceSection">
      <div className="BalanceSection__header">
        <MainHeader text="My Details" />
        <PrimaryButton
          text="Manage"
          onClick={() => dispatch(goToBillingPortal())}
        />
      </div>
      <div className="BalanceSection__main">
        <div className="BalanceSection__item-wrapper">
          <DashboardTotalInfoCard
            title="Plan"
            icon=""
            info={
              <div className="BalanceSection__item--price">
                $
                {planInfo?.subscription?.plan?.amount &&
                  planInfo.subscription.plan.amount / 100}
                <span className="BalanceSection__item--plan">
                  /{planInfo?.subscription.plan.interval}
                </span>
              </div>
            }
            balance
          />
        </div>
        <div className="BalanceSection__item-wrapper">
          <DashboardTotalInfoCard
            title="Subscription end date"
            icon=""
            info={
              <div className="BalanceSection__item--date">
                {secToDate(planInfo?.subscription?.current_period_end)}
              </div>
            }
            balance
          />
        </div>
      </div>
    </div>
  );
}
