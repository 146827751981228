const validateInput = (inputType: string, inputValue: string): string => {
  switch (inputType) {
    case 'name':
      if (!inputValue) {
        return 'Name is required';
      }
      if (!/^[a-zA-Z ]+$/.test(inputValue)) {
        return 'Name must only contain letters';
      }
      return '';
    case 'email':
      if (!inputValue) {
        return 'Email is required';
      }
      if (!/\S+@\S+\.\S+/.test(inputValue)) {
        return 'Email is invalid';
      }
      return '';
    case 'password':
      if (!inputValue) {
        return 'Password is required';
      }
      if (inputValue?.length < 6) {
        return 'Password must contain at least 6 characters';
      }
      return '';
    default:
      return '';
  }
};

export default validateInput;
