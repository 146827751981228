import React, { useEffect, useRef, useState, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getChatHistory,
  sendQuestion,
  deleteChatHistory,
  getAllCoaches,
} from '../../../redux/slices/chatSlice';
import DeleteBtn from '../../Buttons/DeleteBtn';
import Form from '../../Chat/Form';
import Message from '../../Chat/Message';
import StreamMessage from '../../Chat/StreamMessage';
import assistantIcon from '../../../images/utils-icons/assistant.png';
import { AppDispatch, RootState } from '../../../redux/store';
import Modal from '../../Modal';
import SelectModelDropdown from '../../SelectModelDropdown';
import './CoachSection.scss';

export default function CoachSection() {
  const [deleteModal, setDeleteModal] = useState(false);
  const [uniqueMessages, setUniqueMessages] = useState<any[]>([]);
  const dispatch = useDispatch<AppDispatch>();
  // eslint-disable-next-line react-redux/useSelector-prefer-selectors
  const messages = useSelector((state: RootState) => state.chat.messages);
  // eslint-disable-next-line react-redux/useSelector-prefer-selectors
  const writing = useSelector((state: RootState) => state.chat.writing);
  const myDivRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    dispatch(getAllCoaches());
    dispatch(getChatHistory());
  }, []);

  useEffect(() => {
    const div = myDivRef.current;
    if (div) {
      setTimeout(() => {
        div.scrollTop = div.scrollHeight;
      }, 10);
    }
  }, [messages]);
  return (
    <>
      <section className="coach">
        <div className="coach__top-bar">
          <div className="coach__role-wrapper">
            <img src={assistantIcon} alt="assistant" />
            <SelectModelDropdown type="role" />
          </div>
          <DeleteBtn onDelete={() => setDeleteModal(true)} />
        </div>
        {!messages?.length && (
          <p className="coach__default-message">No messages yet</p>
        )}
        {Array.isArray(messages) ? (
          <div
            ref={myDivRef}
            className="coach__message-list"
            style={writing ? { overflow: 'hidden' } : {}}
          >
            {messages.map(({ content, role, id }) => (
              <Message
                key={id}
                message={content}
                position={role === 'assistant' ? 'left' : 'right'}
              />
            ))}
          </div>
        ) : (
          <p className="coach__default-message">No messages yet</p>
        )}

        <Form />
      </section>
      {deleteModal && (
        <Modal
          type="delete"
          onSubmit={() => {
            dispatch(deleteChatHistory());
            setDeleteModal(false);
          }}
          onCancel={() => setDeleteModal(false)}
          submitBtnText="Delete"
          cancelBtnText="Cancel"
          title="Confirm delete"
          text="Are you sure you want to delete all history?"
        />
      )}
    </>
  );
}
