import React from 'react';
import { ReactComponent as TrashIcon } from '../../../images/dashbord-icons/trash.svg';
import './ContentItem.scss';
import { RequiredTemplateField } from '../../../types/templates';
import TextArea from '../TextArea/TextArea';

interface ContentItemProps {
  fields: RequiredTemplateField[];
  onDelete: () => void;
  onBlur: (name: string, text: string) => void;
}

function ContentItem({ fields, onDelete, onBlur }: ContentItemProps) {
  return (
    <div className="content-item">
      {fields
        .sort((a, b) => {
          // Check if the date properties are valid date strings
          const aDate = new Date(a.id);
          const bDate = new Date(b.id);
          if (Number.isNaN(aDate.getTime()) || Number.isNaN(bDate.getTime())) {
            return 0;
          }
          // Perform the subtraction operation
          return (aDate.getTime() as number) - (bDate.getTime() as number);
        })
        .map((field) => (
          <TextArea field={field} key={field.name} onBlur={onBlur} />
        ))}
      <button type="button" onClick={onDelete}>
        <TrashIcon />
      </button>
    </div>
  );
}

export default ContentItem;
