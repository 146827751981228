import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PrimaryButton from '../Buttons/PrimaryButton';
import SecondaryButton from '../Buttons/SecondaryButton';
import { ReactComponent as PlusIcon } from '../../images/dashbord-icons/plus.svg';
import './ScratchPage.scss';
import ContentItem from './ContentItem';
import OptionalField from '../OptionalField';
import Loader from '../Loader/Loader';
import { AppDispatch, RootState } from '../../redux/store';
import {
  OptionalTemplateField,
  SendVariable,
  Template,
  RequiredTemplateField,
} from '../../types/templates';
import {
  clearQuestions,
  createArtifacts,
  getTemplateQuestion,
  setActiveTemplate,
  setHasError,
} from '../../redux/slices/templateSlice';

export default function ScratchPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [searchParams] = useSearchParams() || '';
  const categoryId = searchParams.get('categoryId') || '';
  const templateId = searchParams.get('templateId') || '';
  const [emptyValue, setEmptyValue] = useState<string[]>([]);

  const { loading, templates, hasError, questions, activeTemplate } =
    // eslint-disable-next-line react-redux/useSelector-prefer-selectors
    useSelector((state: RootState) => state.templates);

  const [tempQuestions, setTempQuestions] = useState<any>(null);
  const [requiredFields, setRequiredFields] = useState<RequiredTemplateField[]>(
    [],
  );
  const [optionalFields, setOptionalFields] = useState<OptionalTemplateField[]>(
    [],
  );

  const [artifactItems, setArtifactItems] = useState<
    {
      localId: number;
      fields: RequiredTemplateField[];
    }[]
  >([]);
  const [artifactOptions, setArtifactOptions] = useState<SendVariable[]>([]);

  useEffect(() => {
    if (templates) {
      const category = templates.find(
        (item: Template) => item?.id === +categoryId,
      );
      const temp = category?.templates?.find(
        (item: Template) => item?.id === +templateId,
      );
      if (temp) {
        dispatch(setActiveTemplate(temp));
        dispatch(getTemplateQuestion(temp?.id));
      } else {
        dispatch(getTemplateQuestion(+templateId));
      }
    }

    return () => {
      dispatch(clearQuestions());
      dispatch(setHasError(false));
    };
  }, [templates, templateId, categoryId]);

  useEffect(() => {
    setTempQuestions(questions);
  }, [questions]);

  useEffect(() => {
    if (tempQuestions) {
      const reqFields = tempQuestions
        .filter((item: any) => item?.input_type === 'text')
        .map(
          (field: any): RequiredTemplateField => ({
            id: field.id,
            description: field.description,
            name: field.name,
            placeholder: field.placeholder,
            value: field?.default,
            required: field.required ? field.required : false,
          }),
        );
      const optFields = tempQuestions
        .filter((item: any) => item?.input_type !== 'text')
        .map(
          (field: any): OptionalTemplateField => ({
            id: field.id,
            name: field.name,
            placeholder: field.placeholder,
            value: field.default,
            input: field?.input_type,
            title: field.description,
            description: field.description,
            select: field?.select?.split(',') || [],
            min: field.min,
            max: field.max,
            required: false,
          }),
        );

      reqFields.forEach((item: any) => {
        if (item.required) setEmptyValue([...emptyValue, item.name]);
      });
      setRequiredFields(reqFields);
      setOptionalFields(optFields);
      setArtifactItems([
        {
          localId: Math.floor(Math.random() * 100000),
          fields: reqFields,
        },
      ]);
      setArtifactOptions(optFields);
    }
  }, [tempQuestions]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    let error = false;

    const artifactsToRequest = artifactItems.map((item) => ({
      name: 'Any name',
      type: activeTemplate?.type || '',
      variables: [
        ...item.fields.map((field) => {
          if (!field.value && field.required) error = true;
          return {
            name: field.name,
            value: field.value,
          };
        }),
        ...artifactOptions.map((option) => ({
          name: option.name,
          value: option.value,
        })),
      ],
    }));

    if (error) {
      dispatch(setHasError(true));
      // return;
    }

    if (!hasError && !emptyValue.length) {
      await dispatch(createArtifacts(artifactsToRequest));
      navigate(`/result?categoryId=${categoryId}&templateId=${templateId}`, {
        replace: true,
      });
    }
  };

  const handleDelete = (item: {
    localId: number;
    fields: RequiredTemplateField[];
  }) => {
    if (artifactItems.length === 1) return;
    setArtifactItems(artifactItems.filter((i) => i.localId !== item.localId));
  };

  const createNewArtifact = (fields: RequiredTemplateField[]) => ({
    localId: Math.floor(Math.random() * 100000),
    fields: fields.map(({ id, description, name, placeholder, required }) => ({
      id,
      name,
      description,
      placeholder,
      required,
      value: '',
    })),
  });

  const handleBlur = (
    item: {
      localId: number;
      fields: {
        id: number;
        name: string;
        value: string | boolean | number;
        required: boolean;
      }[];
    },
    name: string,
    text: string,
  ) => {
    const currentArtifact = artifactItems.find(
      (i) => i.localId === item.localId,
    );
    if (currentArtifact) {
      const currentField = currentArtifact.fields.find((f) => f.name === name);
      if (currentField) {
        currentField.value = text;
        if (currentField?.required && !text)
          setEmptyValue([...emptyValue, name]);
        else {
          const names = emptyValue.filter((a: string) => a !== name);
          setEmptyValue(names);
        }
      }
    }

    // if (!text) {
    //   setEmptyValue([...emptyValue, name]);
    // } else {
    //   const names = emptyValue.filter((a: string) => a !== name);
    //   setEmptyValue(names);
    // }
  };

  // Detect if scrollable content is bigger than container
  const scrollableContainer = useRef<HTMLDivElement | null>(null);
  const scrollableContent = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (scrollableContainer.current) {
      const containerComputedHeight = window.getComputedStyle(
        scrollableContainer.current as Element,
      ).height;
      const contentComputedHeight = window.getComputedStyle(
        scrollableContent.current as Element,
      ).height;

      const containerHeight = parseInt(
        containerComputedHeight.slice(0, containerComputedHeight.length - 2),
        10,
      );

      const contentHeight = parseInt(
        contentComputedHeight.slice(0, contentComputedHeight.length - 2),
        10,
      );

      if (contentHeight > containerHeight) {
        scrollableContainer.current.style.paddingRight = '10px';
      } else scrollableContainer.current.style.paddingRight = '0';
    }
  }, [artifactItems.length]);

  // console.log(
  //   artifactItems[0].fields.some((obj) =>
  //     Object.values(obj).some((value) => value === undefined || value === ''),
  //   ),
  // );

  return (
    <div className="scratch-page">
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="scratch-page__header">
            <h2 className="scratch-page__title">{activeTemplate?.title}</h2>
            <div className="scratch-page__btn-group">
              {/* <PrimaryButton
                text="Upload XLSX"
                onClick={() =>
                  navigate(`/upload?${searchParams.toString()}`, {
                    replace: true,
                  })
                }
              /> */}
              <PrimaryButton
                text="Create from Scratch"
                onClick={() =>
                  navigate(`/create?${searchParams.toString()}`, {
                    replace: true,
                  })
                }
                disabled
              />
            </div>
          </div>
          <SecondaryButton
            position="right"
            text="Create New One"
            iconPosition="left"
            icon={<PlusIcon />}
            onClick={() => {
              setArtifactItems([
                ...artifactItems,
                createNewArtifact(requiredFields),
              ]);
            }}
          />
          <div className="scratch-page__content" ref={scrollableContainer}>
            <div
              className="scratch-page__inner"
              ref={scrollableContent}
              style={{ width: '100%' }}
            >
              <div className="scratch-page__content-list">
                {artifactItems?.map((item) => {
                  return (
                    <ContentItem
                      key={item.localId}
                      fields={item.fields}
                      onDelete={() => handleDelete(item)}
                      onBlur={(name: string, text: string) =>
                        handleBlur(item, name, text)
                      }
                    />
                  );
                })}
              </div>
              <div className="scratch-page__optional-fields">
                {optionalFields?.map((item) => {
                  return (
                    <OptionalField
                      key={Math.floor(Math.random() * 100000)}
                      input={item.input}
                      title={item.title}
                      default={item.value}
                      select={item.select}
                      name={item.name}
                      min={item.min}
                      max={item.max}
                      onChange={(
                        name: string,
                        value: SendVariable['value'],
                      ) => {
                        setArtifactOptions((state) => {
                          const currentField = state.find(
                            (variable) => variable.name === name,
                          );
                          if (currentField) currentField.value = value;
                          return state;
                        });
                      }}
                    />
                  );
                })}
              </div>
            </div>

            <PrimaryButton
              text="Submit"
              disabled={hasError}
              style={{ alignSelf: 'center', marginTop: '20px' }}
              onClick={handleSubmit}
            />
          </div>
        </>
      )}
    </div>
  );
}
