import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RequiredTemplateField } from '../../../types/templates';
import { AppDispatch, RootState } from '../../../redux/store';
import { setHasError } from '../../../redux/slices/templateSlice';
import './TextArea.scss';

interface Props {
  field: RequiredTemplateField;
  onBlur: (name: string, text: string) => void;
}

export default function TextArea({ field, onBlur }: Props) {
  const dispatch = useDispatch<AppDispatch>();
  const [height, setHeight] = useState('');
  const [value, setValue] = useState('');

  const [isEmpty, setIsEmpty] = useState(false);

  // eslint-disable-next-line react-redux/useSelector-prefer-selectors
  const { hasError } = useSelector((state: RootState) => state.templates);

  useEffect(() => {
    if (hasError && !value) {
      setIsEmpty(true);
    }
  }, [hasError]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
    setHeight(`${e.target.scrollHeight}px`);
    dispatch(setHasError(false));
    setIsEmpty(false);
  };

  return (
    <div className="content-item__inner" key={field?.name}>
      {/* <h3 className="content-item__title">{field.description}</h3> */}
      <div>
        <p className="textarea__text">{field?.placeholder}</p>
        {isEmpty && <span className="textarea__error">Required field</span>}
        <textarea
          className={isEmpty ? 'error' : ''}
          value={value}
          style={{ height }}
          onChange={handleChange}
          onBlur={() => {
            if (value === '' && field.required) {
              setIsEmpty(true);
            } else {
              setIsEmpty(false);
            }
            onBlur(field.name, value);
          }}
        />
      </div>
    </div>
  );
}
