import React from 'react';
import './MainHeader.scss';

type Props = {
  text: string;
};

export default function MainHeader({ text }: Props) {
  return (
    <div className="MainHeader">
      <h2 className="MainHeader__title">{text}</h2>
    </div>
  );
}
