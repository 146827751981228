import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getAllArtifacts } from '../../../redux/slices/templateSlice';
import { getUserInfo } from '../../../redux/slices/authSlice';
import { AppDispatch, RootState } from '../../../redux/store';

import { arrTotalInfo, TotalInfo } from '../../../api/dashboardPlacehplders';
import DashboardTotalInfoCard from '../../Cards/DashboardTotalInfoCard';
import MainHeader from '../../MainHeader';
import DashboardList from './DashboardList';
import Loader from '../../Loader';

import './DashboardSection.scss';

export default function DashboardSection() {
  const dispatch = useDispatch<AppDispatch>();

  const [skip, setSkip] = useState<number>(0);
  const [value, setValue] = useState<string>('');
  const [filter, setFilter] = useState<string[]>([]);

  // eslint-disable-next-line react-redux/useSelector-prefer-selectors
  const { loading, artifacts, artifactsCount } = useSelector(
    (state: RootState) => state.templates,
  );
  // eslint-disable-next-line react-redux/useSelector-prefer-selectors
  const { userInfo, dashLoading } = useSelector(
    (state: RootState) => state.auth,
  );

  useEffect(() => {
    dispatch(getUserInfo());
  }, []);

  useEffect(() => {
    const data = {
      skip,
      search: value || '',
      filter: filter.map((item) => item.replace(' ', '')).join(','),
    };
    dispatch(getAllArtifacts(data));
  }, [skip, value, filter]);

  const changeInfoValue = () => {
    return arrTotalInfo.map((item: TotalInfo, i: number) => {
      const obj = {
        ...item,
      } as any;

      if (userInfo) {
        if (i === 0) {
          obj.info = userInfo?.artifactsCount;
        } else if (i === 1) {
          obj.info = userInfo?.filesGenerated;
        } else {
          obj.info = userInfo?.timeSaved;
        }
      }

      return obj;
    });
  };

  function handleScroll(e: React.UIEvent<HTMLDivElement>) {
    const element = e.currentTarget;

    if (
      element &&
      element.scrollHeight - element.scrollTop === element.clientHeight &&
      artifactsCount > artifacts?.length
    ) {
      setSkip((prevSkip) => prevSkip + 20);
    }
  }

  return (
    <div className="DashboardSection">
      {loading || dashLoading ? (
        <Loader />
      ) : (
        <>
          <div className="DashboardSection__header--padding">
            <MainHeader text={`Welcome, ${userInfo?.name}`} />
          </div>
          <main className="DashboardSection__main" onScroll={handleScroll}>
            <div className="DashboardSection__row">
              <h2 className="DashboardSection__subtitle">My Dashboard</h2>
              <div className="DashboardSection__row-list">
                {changeInfoValue().map((item: TotalInfo) => (
                  <DashboardTotalInfoCard
                    key={item.title}
                    title={item.title}
                    icon={item.icon}
                    info={item.info}
                    balance={false}
                  />
                ))}
              </div>
            </div>
            <div className="DashboardSection__list-wrapper">
              <DashboardList
                setSkip={setSkip}
                setValue={setValue}
                setFilter={setFilter}
              />
            </div>
          </main>
        </>
      )}
    </div>
  );
}
