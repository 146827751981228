import React from 'react';
import './MiniLoader.scss';

export default function MiniLoader() {
  return (
    <div className="MiniLoader">
      <div id="MiniLoader__wrapper">
        <div className="MiniLoader__profile-main-loader">
          <div className="MiniLoader__loader">
            <svg className="MiniLoader__circular-loader" viewBox="25 25 50 50">
              <circle
                className="MiniLoader__loader-path"
                cx="50"
                cy="50"
                r="20"
                fill="none"
                stroke="#acadad"
                strokeWidth="2"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}
