import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Template } from '../../types/templates';
import { RootState } from '../../redux/store';

import check from '../../images/utils-icons/check-circle-2.svg';
import MiniLoader from './MiniLoader';
import './Loader.scss';

export default function Loader() {
  const location = window.location.pathname;
  const [searchParams] = useSearchParams() || '';
  const type = searchParams.get('type') || '';

  const [isLoaderOne, setLoaderOne] = useState(true);
  const [isLoaderTwo, setLoaderTwo] = useState(true);
  const [template, setTemplate] = useState<null | Template>(null);

  // eslint-disable-next-line react-redux/useSelector-prefer-selectors
  const { templates } = useSelector((state: RootState) => state.templates);

  useEffect(() => {
    if (templates) {
      const temp = templates?.find((item: Template) => item?.type === type);
      if (temp) {
        setTemplate(temp);
      }
    }
  }, [templates, type]);

  useEffect(() => {
    setTimeout(() => {
      setLoaderOne(false);
    }, 3000);

    setTimeout(() => {
      setLoaderTwo(false);
    }, 10000);
  }, []);

  return location === '/create' || location === '/upload' ? (
    <div className="LoaderScratch">
      <h2 className="LoaderScratch__title">{template?.title} Output</h2>

      <div className="LoaderScratch__main">
        <div className="LoaderScratch__main-row">
          {isLoaderOne ? <MiniLoader /> : <img src={check} alt="check" />}
          <b>Analyzing Your Request:</b> We`re carefully reading and
          understanding your input.
        </div>
        <div className="LoaderScratch__main-row">
          {isLoaderTwo ? <MiniLoader /> : <img src={check} alt="check" />}
          <b>Fine-tuning Your Results:</b> Hang on, we`re perfecting your
          results to ensure the utmost quality and relevance.
        </div>
        <div className="LoaderScratch__main-row">
          <MiniLoader />
          <b>Preparing Your Artifact:</b> Just a moment more, we`re crafting
          your unique artifact and will have it ready for you shortly.
        </div>
      </div>
    </div>
  ) : (
    <div className="Loader">
      <h2 className="Loader__title">Loading..</h2>

      <div id="wrapper">
        <div className="profile-main-loader">
          <div className="loader">
            <svg className="circular-loader" viewBox="25 25 50 50">
              <circle
                className="loader-path"
                cx="50"
                cy="50"
                r="20"
                fill="none"
                stroke="#1363DF"
                strokeWidth="2"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}
