/* eslint-disable react/no-children-prop */
import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';

import MainBtn from '../Buttons/MainBtn';
import { Artifact, Template } from '../../types/templates';
import trash from '../../images/dashbord-icons/trash-black.svg';
import saveCSV from '../../utils/saveCSV';
import './ResultPage.scss';
import CopyButton from '../Buttons/CopyButton';
import { deleteArtifact } from '../../redux/slices/templateSlice';

export default function ResultPage() {
  const [searchParams] = useSearchParams() || '';
  const type = searchParams.get('type') || '';

  const [template, setTemplate] = useState<null | Template>(null);

  // eslint-disable-next-line react-redux/useSelector-prefer-selectors
  const { templates, stories } = useSelector(
    (state: RootState) => state.templates,
  );

  useEffect(() => {
    if (templates) {
      const temp = templates?.find((item: Template) => item?.type === type);
      if (temp) {
        setTemplate(temp);
      }
    }
  }, [templates, type]);
  const dispatch = useDispatch<AppDispatch>();

  return (
    <div className="ResultPage">
      <div className="ResultPage__header">
        <h2 className="ResultPage__title">{template?.title} Output</h2>
        <button
          type="button"
          className="ResultPage__btn"
          onClick={() =>
            saveCSV(
              stories
                ?.map((story: Artifact) => story?.text)
                .join('\n')
                .trim() || '',
            )
          }
        >
          <MainBtn text="Export All to CSV" type="big" disable={false} />
        </button>
      </div>
      <div className="ResultPage__container">
        {stories?.map((story: Artifact) => (
          <div key={story?.id} className="ResultPage__main-wrapper">
            <div className="btn-group">
              <CopyButton
                onCopy={() =>
                  navigator.clipboard.writeText(story?.text?.trim())
                }
              />
              <button
                type="button"
                onClick={() => saveCSV(story?.text?.trim())}
              >
                <MainBtn text="Export" type="small" disable={false} />
              </button>
              <button
                className="DashboardCreatedCard__btn"
                type="button"
                onClick={() => {
                  dispatch(deleteArtifact(story.id));
                }}
              >
                <img src={trash} alt="trash" />
              </button>
            </div>
            <ReactMarkdown
              children={story?.text}
              className="ResultPage__main"
              remarkPlugins={[remarkGfm]}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
