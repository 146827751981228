/* eslint-disable */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from '../../redux/store';
import { endPriceLoading } from '../../redux/slices/authSlice';
import { IconAlbum, IconAward, IconBadgeCheck, IconBell, IconPiggyBank, IconSun, IconActivity} from '../../icons';
import logo from '../../images/full-logo.png';
import logoDark from '../../images/full-logo-dark.png';
import PricingCard from '../PricingCard';

import './PricingPage.scss';

const plans = [
    {
        title: 'TRY FREE TRIAL FOR 7 DAYS',
        description: 'Explore Nesmo.ai: Unleash the Power of Flexible AI with our No-Cost Trial',
        price: '',
        benefits: [
            {
                title: 'Nesmo.ai Flexi-Trial',
                sub_title: '',
                icon: <IconSun />
            },
            {
                title: 'Nesmo.ai Adapt-Away Offer',
                sub_title: '',
                icon: <IconAlbum />
            },
            {
                title: 'Nesmo.ai Uninterrupted Experience',
                sub_title: '',
                icon: <IconBell />
            },
            {
                title: 'Nesmo.ai CustomFit Trial',
                sub_title: '',
                icon: <IconActivity />
            }
        ],
        valuable: false,
        price_id: 'price_1NmvADDhjSHesrhjQYBmJZxT',
        bg: 'white'
    },
    {
        title: 'Monthly',
        description: 'Flexibility and Freedom with Monthly Billing!',
        price: '$40',
        benefits: [
            {
                title: 'Flexibility',
                sub_title: 'Pay month-to-month',
                icon: <IconSun />
            },
            {
                title: 'Adaptability',
                sub_title: 'Adjust as needed',
                icon: <IconAlbum />
            },
            {
                title: 'Uninterrupted Service',
                sub_title: 'Enjoy Nesmo.ai all month',
                icon: <IconBell />
            }
        ],
        valuable: false,
        price_id: 'price_1NmvADDhjSHesrhjQYBmJZxT',
        bg: 'blue'
    },
    {
        title: 'Yearly',
        description: 'Save More with our Yearly Subscription!',
        price: '$32',
        benefits: [
            {
                title: 'Cost Savings',
                sub_title: 'Significant discounts',
                icon: <IconPiggyBank />
            },
            {
                title: 'Uninterrupted Service',
                sub_title: 'Whole year access',
                icon: <IconAward />
            },
            {
                title: 'Convenient Commitment',
                sub_title: 'One-time payment',
                icon: <IconBadgeCheck />
            }
        ],
        valuable: true,
        price_id: 'price_1NmvADDhjSHesrhjZnrtc6cN',
        bg: 'gradient'
    }
];

function PricingPage(){
    const dispatch = useDispatch<AppDispatch>();

    // eslint-disable-next-line react-redux/useSelector-prefer-selectors
    const { userInfo, darkMode } = useSelector((state: RootState) => state.auth);

    useEffect(() => {
      dispatch(endPriceLoading());
    }, [])

    return (
        <div className='PricingPage'>
            <div className="PricingPage__header">
                <div className="PricingPage__logo">
                    <img src={darkMode ? logoDark :logo} alt="logo" />
                </div>
                <div className="PricingPage__title">
                    <h1>Choose your plan</h1>
                </div>
            </div>
            <div className="PricingPage__plans">
                {plans.map(plan => {
                    if (userInfo?.trial) {
                        if (plan.bg !== 'white') {
                           return <PricingCard key={plan.price_id} {...plan} /> 
                        }
                    } else {
                        return <PricingCard key={plan.price_id} {...plan} />
                    }
                })}
            </div>
        </div>
    )
}

export default PricingPage;