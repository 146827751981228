import React from 'react';
import './PrimaryButton.scss';

interface PrimaryButtonProps {
  style?: React.CSSProperties;
  text: string;
  disabled?: boolean;
  onClick: (event: any) => Promise<void> | void;
}

function PrimaryButton({ text, disabled, style, onClick }: PrimaryButtonProps) {
  return (
    <button
      className="primary-btn"
      type="button"
      style={style}
      disabled={disabled}
      onClick={onClick}
    >
      {text}
    </button>
  );
}

PrimaryButton.defaultProps = {
  disabled: false,
  style: {},
};

export default PrimaryButton;
