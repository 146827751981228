/* eslint-disable */
export function IconRocket(){
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.00001 11.0002C2.00001 11.8402 1.66667 14.3335 1.66667 14.3335C1.66667 14.3335 4.16001 14.0002 5.00001 13.0002C5.47334 12.4402 5.46667 11.5802 4.94001 11.0602C4.68088 10.8129 4.33953 10.67 3.98149 10.6589C3.62344 10.6478 3.27392 10.7694 3.00001 11.0002Z" stroke="#070707" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8 9.99984L6 7.99984C6.35476 7.07946 6.80147 6.19722 7.33333 5.36651C8.11012 4.1245 9.19175 3.10187 10.4753 2.3959C11.7589 1.68993 13.2018 1.32409 14.6667 1.33317C14.6667 3.14651 14.1467 6.33317 10.6667 8.66651C9.82459 9.19899 8.93123 9.64567 8 9.99984Z" stroke="#070707" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6 8.00034H2.66667C2.66667 8.00034 3.03334 5.98034 4.00001 5.33367C5.08 4.61367 7.33334 5.33367 7.33334 5.33367" stroke="#070707" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8 10.0003V13.3337C8 13.3337 10.02 12.967 10.6667 12.0003C11.3867 10.9203 10.6667 8.66699 10.6667 8.66699" stroke="#070707" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

export function IconSun(){
    return (
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.50009 10.6663C9.97285 10.6663 11.1668 9.47243 11.1668 7.99967C11.1668 6.52691 9.97285 5.33301 8.50009 5.33301C7.02733 5.33301 5.83342 6.52691 5.83342 7.99967C5.83342 9.47243 7.02733 10.6663 8.50009 10.6663Z" stroke="#070707" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.5 1.33301V2.66634" stroke="#070707" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.5 13.333V14.6663" stroke="#070707" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M3.78654 3.28711L4.72654 4.22711" stroke="#070707" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.2732 11.7734L13.2132 12.7134" stroke="#070707" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M1.83342 8H3.16675" stroke="#070707" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M13.8334 8H15.1668" stroke="#070707" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M4.72654 11.7734L3.78654 12.7134" stroke="#070707" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M13.2132 3.28711L12.2732 4.22711" stroke="#070707" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

export function IconAlbum(){
    return(
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.1667 2H3.83333C3.09695 2 2.5 2.59695 2.5 3.33333V12.6667C2.5 13.403 3.09695 14 3.83333 14H13.1667C13.903 14 14.5 13.403 14.5 12.6667V3.33333C14.5 2.59695 13.903 2 13.1667 2Z" stroke="#070707" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.83315 2V7.33333L9.83315 5.33333L11.8331 7.33333V2" stroke="#070707" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

export function IconBell(){
    return (
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.5 5.33301C4.5 4.27214 4.92143 3.25473 5.67157 2.50458C6.42172 1.75444 7.43913 1.33301 8.5 1.33301C9.56087 1.33301 10.5783 1.75444 11.3284 2.50458C12.0786 3.25473 12.5 4.27214 12.5 5.33301C12.5 9.99967 14.5 11.333 14.5 11.333H2.5C2.5 11.333 4.5 9.99967 4.5 5.33301Z" stroke="#070707" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.36662 14C7.47821 14.203 7.64226 14.3722 7.84162 14.4901C8.04098 14.608 8.26834 14.6702 8.49996 14.6702C8.73157 14.6702 8.95893 14.608 9.1583 14.4901C9.35766 14.3722 9.5217 14.203 9.63329 14" stroke="#070707" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

export function IconPiggyBank(){
    return (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.1667 3.83301C12.1667 3.83301 11.3 4.76634 11.1667 5.16634C8.83336 4.16634 3.83336 4.96634 3.83336 8.49967C3.83336 9.69967 3.83336 10.4997 5.16669 11.4997V13.833H7.83336V12.4997H9.83336V13.833H12.5V11.1663C13.1667 10.833 13.6334 10.4997 13.8334 9.83301H15.1667V7.16634H13.8334C13.8334 6.49967 13.5 6.16634 13.1667 5.83301V3.83301Z" stroke="black" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M1.83336 6.5V7.16667C1.83336 7.9 2.43336 8.5 3.16669 8.5H3.83336" stroke="black" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

export function IconAward(){
    return (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.5 9.83301C10.7091 9.83301 12.5 8.04215 12.5 5.83301C12.5 3.62387 10.7091 1.83301 8.5 1.83301C6.29086 1.83301 4.5 3.62387 4.5 5.83301C4.5 8.04215 6.29086 9.83301 8.5 9.83301Z" stroke="black" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10.818 9.09375L11.8333 15.1671L8.5 13.1671L5.16667 15.1671L6.182 9.09375" stroke="black" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

export function IconBadgeCheck(){
    return (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.06667 6.24608C2.96937 5.80776 2.98431 5.35198 3.11011 4.92097C3.23591 4.48997 3.4685 4.09771 3.78632 3.78056C4.10413 3.46341 4.49688 3.23164 4.92814 3.10674C5.35941 2.98184 5.81523 2.96786 6.25334 3.06608C6.49448 2.68895 6.82668 2.37859 7.21931 2.1636C7.61194 1.94862 8.05237 1.83594 8.50001 1.83594C8.94764 1.83594 9.38807 1.94862 9.78071 2.1636C10.1733 2.37859 10.5055 2.68895 10.7467 3.06608C11.1855 2.96743 11.6421 2.98135 12.074 3.10656C12.506 3.23176 12.8992 3.46417 13.2172 3.78218C13.5352 4.10019 13.7677 4.49346 13.8929 4.92542C14.0181 5.35737 14.032 5.81397 13.9333 6.25275C14.3105 6.49389 14.6208 6.82608 14.8358 7.21871C15.0508 7.61135 15.1635 8.05178 15.1635 8.49941C15.1635 8.94705 15.0508 9.38748 14.8358 9.78011C14.6208 10.1727 14.3105 10.5049 13.9333 10.7461C14.0316 11.1842 14.0176 11.64 13.8927 12.0713C13.7678 12.5025 13.536 12.8953 13.2189 13.2131C12.9017 13.5309 12.5094 13.7635 12.0784 13.8893C11.6474 14.0151 11.1917 14.0301 10.7533 13.9327C10.5125 14.3113 10.1801 14.623 9.78676 14.839C9.39346 15.0549 8.95202 15.1681 8.50334 15.1681C8.05466 15.1681 7.61322 15.0549 7.21992 14.839C6.82662 14.623 6.49417 14.3113 6.25334 13.9327C5.81523 14.031 5.35941 14.017 4.92814 13.8921C4.49688 13.7672 4.10413 13.5354 3.78632 13.2183C3.4685 12.9011 3.23591 12.5089 3.11011 12.0779C2.98431 11.6469 2.96937 11.1911 3.06667 10.7527C2.68664 10.5122 2.37362 10.1795 2.15671 9.78556C1.9398 9.39158 1.82605 8.94915 1.82605 8.49941C1.82605 8.04968 1.9398 7.60724 2.15671 7.21327C2.37362 6.8193 2.68664 6.48659 3.06667 6.24608Z" stroke="black" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6.5 8.50033L7.83333 9.83366L10.5 7.16699" stroke="black" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

export function IconActivity(){
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M12.6667 2H3.33333C2.59695 2 2 2.59695 2 3.33333V12.6667C2 13.403 2.59695 14 3.33333 14H12.6667C13.403 14 14 13.403 14 12.6667V3.33333C14 2.59695 13.403 2 12.6667 2Z" stroke="black" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M11.3333 8.00033H9.99999L8.66666 11.3337L7.33332 4.66699L5.99999 8.00033H4.66666" stroke="black" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}