import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { RootState } from '../../redux/store';
import { Template } from '../../types/templates';

import Video from './Video';
import './ExplainPage.scss';
import PrimaryButton from '../Buttons/PrimaryButton';

export default function ExplainPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams() || '';
  const categoryId = searchParams.get('categoryId') || '';
  const templateId = searchParams.get('templateId') || '';

  const [template, setTemplate] = useState<null | Template>(null);

  // eslint-disable-next-line react-redux/useSelector-prefer-selectors
  const { templates } = useSelector((state: RootState) => state.templates);

  useEffect(() => {
    if (templates) {
      const category = templates.find(
        (item: Template) => item?.id === +categoryId,
      );
      const temp = category?.templates?.find(
        (item: Template) => item?.id === +templateId,
      );
      if (temp) {
        setTemplate(temp);
      }
    }
  }, [templates]);

  return (
    <div className="ExplainPage">
      <h2 className="ExplainPage__title">{template?.title}</h2>
      <div className="ExplainPage__btns">
        {/* <PrimaryButton
          text="Upload XLSX"
          onClick={() =>
            navigate(`/upload?${searchParams.toString()}`, {
              replace: true,
            })
          }
        /> */}
        <PrimaryButton
          text="Create from Scratch"
          onClick={() =>
            navigate(`/create?${searchParams.toString()}`, {
              replace: true,
            })
          }
        />
      </div>
      <div className="ExplainPage__main">
        <Video
          title={`What is ${template?.title}?`}
          description={template?.description || ''}
          url={template?.video || ''}
        />
      </div>
    </div>
  );
}
