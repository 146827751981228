import React from 'react';
import './DashboardTotalInfoCard.scss';

type Props = {
  title: string;
  icon: string;
  info: number | React.ReactNode | string;
  balance: boolean | null;
};

export default function DashboardTotalInfoCard({
  title,
  icon,
  info,
  balance,
}: Props) {
  return (
    <div className="DashboardTotalInfoCard">
      <div
        className={
          balance === true
            ? 'DashboardTotalInfoCard__header balance'
            : 'DashboardTotalInfoCard__header'
        }
      >
        <h3
          className={
            balance === true
              ? 'DashboardTotalInfoCard__header-title balance'
              : 'DashboardTotalInfoCard__header-title'
          }
        >
          {title}
        </h3>
        {icon && (
          <img
            src={icon}
            alt="icon"
            className="DashboardTotalInfoCard__header-img"
          />
        )}
      </div>
      <div className="DashboardTotalInfoCard__main">
        {typeof info === 'number' ? <p>{info}</p> : info}
      </div>
    </div>
  );
}
