import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import sendIcon from '../../../images/utils-icons/send.svg';
import './Form.scss';
import { AppDispatch, RootState } from '../../../redux/store';
import { sendQuestion } from '../../../redux/slices/chatSlice';

interface FormProps {
  setMessage: (message: string) => void;
}

export default function Form() {
  const dispatch = useDispatch<AppDispatch>();
  const [currentMessage, setCurrentMessage] = useState<string>('');
  // eslint-disable-next-line react-redux/useSelector-prefer-selectors
  const { writing, error, coachRole } = useSelector(
    (state: RootState) => state.chat,
  );
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setCurrentMessage(event.target.value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    if (currentMessage.trim() === '') return;

    dispatch(
      sendQuestion({
        coachId: coachRole?.id,
        message: currentMessage,
      }),
    );
    setCurrentMessage('');
  };

  return (
    <form onSubmit={handleSubmit} className="chat-form">
      <span className="chat-form__writing">
        {error && <span className="chat-form__error">{error}</span>}
        {writing && `${coachRole?.title} is writing...`}
      </span>
      <div className="chat-form__wrapper">
        <input
          type="text"
          value={currentMessage}
          onChange={handleChange}
          placeholder="Ask me anything that I can help you or your team..."
          className="chat-form__input"
          disabled={writing}
        />
        <button type="submit" className="chat-form__submit" disabled={writing}>
          <img src={sendIcon} alt="send" />
        </button>
      </div>
    </form>
  );
}
