import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  getAllNotifications,
  removeNotification,
} from '../../../redux/slices/inboxSlice';
import { AppDispatch, RootState } from '../../../redux/store';
import trash from '../../../images/utils-icons/trash.svg';
import { Message } from '../../../types/inbox';
import './InboxSection.scss';
import { getRead } from '../../../api/notifications';
import logo from '../../../images/full-logo.png';
import logoDark from '../../../images/full-logo-dark.png';

export default function InboxSection() {
  const dispatch = useDispatch<AppDispatch>();

  const [skip, setSkip] = useState<number>(0);
  const [readColor, setReadColor] = useState<string>('');
  const [unreadColor, setUnReadColor] = useState<string>('');

  // eslint-disable-next-line react-redux/useSelector-prefer-selectors
  const { loading, notifications, notificationsCount } = useSelector(
    (state: RootState) => state.inbox,
  );
  // eslint-disable-next-line react-redux/useSelector-prefer-selectors
  const { darkMode } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    dispatch(getAllNotifications(skip));
  }, [skip]);

  useEffect(() => {
    const read = async () => {
      if (notifications && notifications?.some((a: Message) => !a.isRead)) {
        await getRead();
      }
    };

    read();
  }, [notifications]);

  useEffect(() => {
    if (darkMode) {
      setReadColor('#1A1A1A');
      setUnReadColor('#025');
    } else {
      setReadColor('#E8F2FF');
      setUnReadColor('#C4DDFF');
    }
  }, [darkMode]);

  function handleScroll(e: React.UIEvent<HTMLDivElement>) {
    const element = e.currentTarget;

    if (
      element &&
      element.scrollHeight - element.scrollTop === element.clientHeight &&
      notificationsCount > notifications?.length
    ) {
      setSkip((prevSkip) => prevSkip + 10);
    }
  }

  return (
    <div className="InboxSection">
      <h2 className="InboxSection__title">All Notifications</h2>
      <div className="InboxSection__list" onScroll={handleScroll}>
        {notifications?.map((message: Message) => (
          <div
            className="InboxSection__list-item"
            key={message?.id}
            style={{
              background: message?.isRead ? readColor : unreadColor,
            }}
          >
            <img
              style={{ width: '82px', height: '21px' }}
              src={darkMode ? logoDark : logo}
              alt="Nesmo.ai"
            />
            <div>
              {message?.text}
              {message?.isRead && (
                <button
                  type="button"
                  className="InboxSection__delete-btn"
                  onClick={() => dispatch(removeNotification(message?.id))}
                >
                  <img src={trash} alt=" delete" />
                </button>
              )}
            </div>
          </div>
        ))}
        {loading && (
          <div className="lds-ellipsis-wrapper">
            <div className="lds-ellipsis">
              <div />
              <div />
              <div />
              <div />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
