import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Socket } from 'socket.io-client';
import { v4 as uuidv4 } from 'uuid';
import { deleteHistory, getCoachList, getHistory } from '../../api/chat';

interface Message {
  content: string;
  role: string
  id: number | null
}

interface Coach {
  id: number,
  title: string
}

export interface ChatState {
  error: string | null;
  writing: boolean;
  messages: Message[];
  coachList: Coach[],
  coachRole: Coach | null,
  lastMessage: string | null
}

const initialState: ChatState = {
  error: null,
  writing: false,
  coachList: [],
  messages: [],
  coachRole: null,
  lastMessage: null
}

let socket: Socket

const emit = (event: string, data: any) => new Promise(res => {
  if (socket) {
    socket.emit(event, data, res)
  }
})

export const getChatHistory = createAsyncThunk(
  'chat/getChatHistory',
  async () => {
    try {
      const res = await getHistory();
      return res.data;
    } catch (error: any) {
    }
  }
)
export const sendQuestion = createAsyncThunk(
  'chat/sendQuestion',
  async (obj: any, { rejectWithValue, dispatch }) => {
    try {
      obj.id = uuidv4();

      dispatch(setMessage(obj))
      dispatch(startWriting())
      
      const res = await emit('msg', obj);
      return res;
    } catch (error: any) {
    }
  }
)

export const deleteChatHistory = createAsyncThunk(
  'chat/deleteChatHistory',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      dispatch(clearChatHistory())
      await deleteHistory();
    } catch (error: any) {}
  }
)

export const getAllCoaches = createAsyncThunk(
  'chat/getAllCoaches',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const res = await getCoachList();
      return res.data;
    } catch (error: any) {}
  }
)

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    clearChat: (state) => {
      state.error = null;
      state.writing = false;
      state.messages = [];
    },
    startWriting: (state) => {
      state.writing = true;
    },
    endWriting: (state) => {
      state.writing = false;
    },
    setMessageAssistant: (state, action) => {
      const assistantMessage = state.messages.find((message) => message.id === action.payload.temp.tempAssistId);
      if (assistantMessage) {
        assistantMessage.id = action.payload.data.id;
      }
      state.writing = false;
    },
    setMessage: (state, action) => {
      state.messages = [{id: action.payload.id, content: action.payload.message, role: 'user' }, ...state.messages]
    },
    setLastMessage: (state, action) => {
      const lastMessage = state.messages.find((message) => message.id === action.payload.tempAssistId);
      if (lastMessage) {
        lastMessage.content = action.payload.final;
      }
      else {
        state.messages = [{id: action.payload.tempAssistId, content: action.payload.final, role: 'assistant' }, ...state.messages]
      }
    },
    setMessageUserId: (state, action) => {
      const userMessage = state.messages.find((message) => message.id === action.payload.temp.tempId);
      if (userMessage) {
        userMessage.id = action.payload.data.id;
      }
    },
    setSocket: (state, action) => {
      socket = action.payload;
    },
    setError: (state, action) => {
      state.writing = false
      state.error = action.payload;
    },
    clearChatHistory: (state) => {
      state.messages = initialState.messages;
    },
    setCoach: (state, action) => {
      state.coachRole = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getChatHistory.fulfilled, (state, action: any) => {
        state.messages = action.payload
      })
      // .addCase(sendQuestion.fulfilled, (state, action: any) => {
      //   state.messages = [{ content: action.payload, role: 'assistant' }, ...state.messages];
      // })
      .addCase(getAllCoaches.fulfilled, (state, action: any) => {
        state.coachList = action.payload;
        if (!state.coachRole) {
          state.coachRole = action.payload[0];
        }
      })
  }
})

export const { 
  setCoach, 
  clearChat, 
  startWriting, 
  endWriting, 
  setMessage,
  setLastMessage,
  setMessageAssistant,
  setError, 
  setSocket, 
  clearChatHistory,
  setMessageUserId
} = chatSlice.actions;

export default chatSlice.reducer;
