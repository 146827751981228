import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider, useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import { io } from 'socket.io-client';
import { store, persistor, RootState, AppDispatch } from './redux/store';

import LoginPage from './components/Authorisation/LoginPage';
import Main from './components/Main';
import PrivateRoutes from './utils/PrivateRoutes';
import PrivacyPage from './components/PrivacyPage';
import NewPass from './components/Authorisation/NewPass';
import PricingPage from './components/PricingPage';
import getCookie from './utils/CookieHelper';
import {
  setSocket,
  setError,
  setLastMessage,
  setMessageAssistant,
  setMessageUserId,
} from './redux/slices/chatSlice';
import './App.scss';

function AppWrapper() {
  const token = getCookie('accessToken');
  const dispatch = useDispatch<AppDispatch>();
  // eslint-disable-next-line react-redux/useSelector-prefer-selectors
  const { errorMessageSignUp } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (token) {
      const socket = io(process.env.REDIRECT_URL || '', {
        query: { token },
      });

      socket.on('connect', () => {
        dispatch(setSocket(socket));

        socket.on('ai_error', (data) => {
          dispatch(setError(data));
        });

        socket.on('ai_message', (data) => {
          console.log(data);
          dispatch(setLastMessage(data));
        });

        socket.on('ai_message_final', (data, temp) => {
          console.log(data);
          dispatch(setMessageAssistant({ data, temp }));
        });

        socket.on('ai_user_message', (data, temp) => {
          console.log(data);
          dispatch(setMessageUserId({ data, temp }));
        });
      });
    }
  }, [token]);

  return (
    <Router>
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route element={<Main />} path="/*" />
          <Route element={<PricingPage />} path="/pricing" />
        </Route>
        {!token && (
          <>
            <Route element={<LoginPage />} path="/auth/*" />
            <Route
              element={
                <Navigate to={errorMessageSignUp ? '/auth/signUp' : '/auth'} />
              }
              path=""
            />
            <Route element={<PrivacyPage type="privacy" />} path="/privacy" />
            <Route element={<PrivacyPage type="terms" />} path="/terms" />
            <Route element={<NewPass />} path="/password-recovery/*" />
          </>
        )}
        <Route element={<PrivacyPage type="privacy" />} path="/privacy" />
      </Routes>
    </Router>
  );
}

function InnerApp() {
  // eslint-disable-next-line react-redux/useSelector-prefer-selectors
  const { darkMode } = useSelector((state: RootState) => state.auth);
  return (
    <div className="App" data-theme={darkMode}>
      <AppWrapper />
    </div>
  );
}

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <InnerApp />
      </PersistGate>
    </Provider>
  );
}

export default App;
