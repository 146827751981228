import React, { useEffect, useState } from 'react';
import './Video.scss';

type Props = {
  title: string;
  description: string;
  url: string;
};

interface Text {
  type: 'ul' | 'p';
  items: [string];
}

export default function Video({ title, description, url }: Props) {
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const [textArr, setTextArr] = useState<Text[]>([]);

  useEffect(() => {
    function handleResize() {
      setViewportHeight(window.innerHeight);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const arr: Text[] = [];

    description.split('\n').forEach((text) => {
      if (!text) return;
      if (text[0] === '-') {
        const lastText = arr[arr.length - 1];
        if (lastText && lastText.type === 'ul') {
          lastText.items.push(text.replace('- ', ''));
        } else {
          arr.push({
            type: 'ul',
            items: [text.replace('- ', '')],
          });
        }
      } else {
        arr.push({
          type: 'p',
          items: [text],
        });
      }
    });

    setTextArr(arr);
  }, [description]);

  return (
    <div className="Video">
      <div className="Video__inner">
        <h3 className="Video__title">{title}</h3>
        <div className="Video__frame">
          <iframe
            width={viewportHeight > 800 ? '540' : '340'}
            height={viewportHeight > 800 ? '308' : '200'}
            src={url}
            title={title}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          />
        </div>
        <div className="Video__text">
          {textArr.map((textItem: Text) => {
            if (textItem.type === 'p') {
              return <p key={Math.random() * 10000}>{textItem.items[0]}</p>;
            }
            return (
              <ul key={Math.random() * 10000}>
                {textItem.items.map((text) => (
                  <li key={Math.random() * 10000}>{text}</li>
                ))}
              </ul>
            );
          })}
        </div>
      </div>
    </div>
  );
}
