import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import GoogleBtn from '../../Buttons/GoogleBtn';
import LoginForm from '../LoginForm';
import { RootState } from '../../../redux/store';

export default function SignIn() {
  const navigate = useNavigate();
  // eslint-disable-next-line react-redux/useSelector-prefer-selectors
  const { darkMode } = useSelector((state: RootState) => state.auth);

  return (
    <div className="Login">
      <h2 className="Login__title">Create your account</h2>
      <div className="Login__main">
        <LoginForm type="SIGN UP" />
        <p className="Login__blue Login__blue--margin">or</p>
        <GoogleBtn />
        <div className="Login__line" />
      </div>
      <button
        type="button"
        onClick={() => navigate('/login')}
        className="Login__sign-in Login__blue"
      >
        Already have an account?
      </button>
    </div>
  );
}
