import React from 'react';
import { useSelector } from 'react-redux';
import logo from '../../images/full-logo.png';
import logoDark from '../../images/full-logo-dark.png';
import './Logo.scss';
import { RootState } from '../../redux/store';

function Logo({ auth }: { auth?: boolean }) {
  // eslint-disable-next-line react-redux/useSelector-prefer-selectors
  const { darkMode } = useSelector((state: RootState) => state.auth);
  return (
    <a href="/" className={`logo ${auth && 'auth'}`}>
      <img src={darkMode ? logoDark : logo} alt="Nemso.ai" />
    </a>
  );
}

Logo.defaultProps = {
  auth: false,
};

export default Logo;
