import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {deleteNotification, getHelp, getNotifications, getRead} from '../../api/notifications';
import { Message } from '../../types/inbox';

export interface InboxState {
  loading: boolean;
  notifications: any;
  notificationsCount: number;
  helpList: any[];
}

const initialState: InboxState = {
  loading: false,
  notifications: [],
  notificationsCount: 0,
  helpList: [],
};

export const getAllNotifications = createAsyncThunk(
  'inbox/getAllNotifications',
  async (skip: number, { rejectWithValue, dispatch }) => {
    dispatch(startLoading());
    try {
      const res = await getNotifications(skip);
      dispatch(endLoading());

      return res?.data;
    } catch (error: any) {
      dispatch(endLoading());
    }
  },
);

export const removeNotification = createAsyncThunk(
  'inbox/removeNotification',
  async (id: number, { rejectWithValue, dispatch }) => {
    try {
      await deleteNotification(id);

      return id;
    } catch (error: any) {
      return;
    }
  },
);

export const getHelpList = createAsyncThunk(
  'inbox/getHelpList',
  async (_, { rejectWithValue, dispatch }) => {
    dispatch(startLoading());
    try {
      const res = await getHelp();
    dispatch(endLoading());
      return res?.data;
    } catch (error: any) {
      dispatch(endLoading());
      return;
    }
  },
);

export const inboxSlice = createSlice({
  name: 'inbox',
  initialState,
  reducers: {
    clearInbox: (state) => {
      state.loading = false;
      state.notifications = [];
      state.notificationsCount = 0;
      state.helpList = [];
    },
    startLoading: (state) => {
      state.loading = true;
    },
    endLoading: (state) => {
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(getAllNotifications.fulfilled, (state, action: any) => {
      const newState = [...state.notifications, ...action.payload?.list]?.filter((object: Message, index: number, self: Message[]) =>
        index === self?.findIndex((o: any) => o?.id === object?.id)).sort((a: Message, b: Message) => b?.id - a?.id);
      state.notifications = newState;
      state.notificationsCount = action.payload?.count;
    })
    .addCase(removeNotification.fulfilled, (state, action: any) => {
      state.notifications = state.notifications?.filter((a: any) => a?.id !== action.payload);
      state.notificationsCount = state.notificationsCount - 1;
    })
    .addCase(getHelpList.fulfilled, (state, action: any) => {
      state.helpList = action.payload;
    })
  },
});

export const {
  clearInbox,
  startLoading,
  endLoading,
} = inboxSlice.actions;

export default inboxSlice.reducer;
