import axios from 'axios';

const API = process.env.REACT_APP_API;
export const getHistory = () =>
  axios.get(`${API}/user/history`, {
    withCredentials: true,
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  });

export const deleteHistory = () =>
  axios.delete(`${API}/user/history`, {
    withCredentials: true,
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  });

export const getCoachList = () =>
  axios.get(`${API}/coach/coachIds`, {
    withCredentials: true,
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  });
