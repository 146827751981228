import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux/store';

import { resetArtifacts } from '../../redux/slices/templateSlice';
import './SearchComponent.scss';

type Props = {
  setSearhValue: (value: string) => void;
  setSkip: (arg: number) => void;
};

export default function SearchComponent({ setSearhValue, setSkip }: Props) {
  const dispatch = useDispatch<AppDispatch>();
  const [value, setValue] = useState('');

  useEffect(() => {
    const timer = setTimeout(() => {
      if (value) {
        dispatch(resetArtifacts());
      }

      setSkip(0);
      setSearhValue(value);
    }, 500);

    return () => clearTimeout(timer);
  }, [value, setSearhValue]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  return (
    <input
      className="SearchComponent"
      type="text"
      placeholder="Search"
      value={value}
      onChange={handleChange}
    />
  );
}
