import React from 'react';
import { useSelector } from 'react-redux';
import './DeleteBtn.scss';
import { RootState } from '../../../redux/store';

export default function DeleteBtn({ onDelete }: { onDelete: () => void }) {
  // eslint-disable-next-line react-redux/useSelector-prefer-selectors
  const { darkMode } = useSelector((state: RootState) => state.auth);
  return (
    <button type="button" className="deleteBtn" onClick={onDelete}>
      Clear Chat History
    </button>
  );
}
