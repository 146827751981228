import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import getCookie from './CookieHelper';

function PrivateRoutes() {
  const token = getCookie('accessToken');

  return token ? <Outlet /> : <Navigate to="" />;
}

export default PrivateRoutes;
