import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { clearAuth, LogOutUser } from '../../redux/slices/authSlice';
import { AppDispatch, RootState } from '../../redux/store';

import logOut from '../../images/sidebar-icons/log-out.svg';
import SidebarList from './SidebarList';
import { clearInbox, getAllNotifications } from '../../redux/slices/inboxSlice';
import { clearChat } from '../../redux/slices/chatSlice';
import { clearTemplate } from '../../redux/slices/templateSlice';
import Logo from '../Logo';
import SelectModelDropdown from '../SelectModelDropdown';

import './Sidebar.scss';
import DarkModeToggle from '../DarkModeToggle';

export default function Sidebar() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getAllNotifications(0));
  }, []);

  return (
    <div className="Sidebar">
      <h2 className="Sidebar__title">
        <Logo />
      </h2>
      <div className="Sidebar__dropdown">
        <SelectModelDropdown type="model" />
      </div>
      <SidebarList />
      <button
        type="button"
        className="Sidebar__logout"
        onClick={async () => {
          dispatch(clearAuth());
          dispatch(clearChat());
          dispatch(clearTemplate());
          dispatch(clearInbox());

          await dispatch(LogOutUser());
          navigate('/auth');
        }}
      >
        <img src={logOut} alt="dashboard" className="Sidebar__list-link-icon" />
        Log out
      </button>
      <div className="Sidebar__toggle">
        <DarkModeToggle />
      </div>
    </div>
  );
}
