import React from 'react';
import './TemplateCard.scss';

type Props = {
  title: string;
  text: string;
  icon: string;
};

export default function TemplateCard({ title, text, icon }: Props) {
  return (
    <div className="TemplateCard">
      <h2 className="TemplateCard__title">{title}</h2>
      <div className="TemplateCard__main">
        <div className="TemplateCard__icon">
          <img className="TemplateCard__icon-img" src={icon} alt="icon" />
        </div>
        <p className="TemplateCard__text">
          {text.length > 65 ? `${text.substring(0, 65)}...` : text}
        </p>
      </div>
    </div>
  );
}
