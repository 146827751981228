/* eslint-disable */
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux/store';
import { IconRocket } from '../../icons';
import { redirectToStripe } from '../../redux/slices/authSlice';
import './PricingCard.scss';

interface PricingCardProps {
    title: string;
    description: string;
    price: string;
    benefits?: {
        title: string,
        sub_title: string;
        icon: JSX.Element
    }[];
    price_id: string;
    bg: string;
    valuable: boolean;
}

function PricingCard({
    bg,
    price_id,
    valuable,
    title,
    description,
    benefits,
    price
}: PricingCardProps){
    const dispatch = useDispatch<AppDispatch>();
    return (
        <div className={`PricingCard PricingCard_bg_${bg}`}>
            {bg !== "white" && <div className="PricingCard__header">
                <div className="PricingCard__header_icon">
                    <IconRocket />
                </div>
                {valuable && <div className='PricingCard__tag'><p>Most valuable</p></div>}
            </div>}
           <div className={`PricingCard__title PricingCard__title--${bg}`}>
                <h2>{title}</h2>
                <p>{description}</p>
           </div>
           {bg !== "white" &&  <div className="PricingCard__price">
                <p className='PricingCard__price_price'>{price}</p>
                <p className="PricingCard__price_period">/month</p>
           </div>}
           {bg !== "white" && <div className="PricingCard__divider"></div>}
           <div className={`PricingCard__benefits PricingCard__benefits--${bg}`}>
            {benefits?.map(benefit => (
                <div key={benefit.title} className="PricingCard__benefit" >
                    <div className={`PricingCard__benefit_icon PricingCard__benefit_icon--${bg}`}>
                        {benefit.icon}
                    </div>
                    <div className={`PricingCard__benefit_content PricingCard__benefit_content--${bg}`}>
                        <h3>{benefit.title}</h3>
                        <p>{benefit.sub_title}</p>
                    </div>
                </div>
            ))}
           </div>
           {bg !== "white" && <div className="PricingCard__divider"></div>}
           <button
             type="button"
             className={`PricingCard__get PricingCard__get--${bg}`}
             onClick={() =>  dispatch(redirectToStripe({
                id: price_id,
                trial: bg === 'white' ? 1 : 0
            }))}
            >
              {bg === "white" ? "START FREE TRIAL" : "Get Started"}
            </button>
        </div>
    )
}

export default PricingCard;