import React from 'react';
import { useSelector } from 'react-redux';
import LoginForm from '../LoginForm';
import { RootState } from '../../../redux/store';

export default function ForgotPass() {
  // eslint-disable-next-line react-redux/useSelector-prefer-selectors
  const { darkMode } = useSelector((state: RootState) => state.auth);
  return (
    <div className="Login">
      <LoginForm type="RESET PASSWORD" />
    </div>
  );
}
