import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../redux/store';
import { getHelpList } from '../../../redux/slices/inboxSlice';
import chevron from '../../../images/utils-icons/chevron-down.svg';
import chevronBlue from '../../../images/utils-icons/chevron-blue.svg';
import Loader from '../../Loader';
import './HelpSection.scss';

export default function HelpSection() {
  const dispatch = useDispatch<AppDispatch>();
  const [selectedQuestion, setSelectedQuestion] = useState<number | null>(null);

  // eslint-disable-next-line react-redux/useSelector-prefer-selectors
  const { helpList, loading } = useSelector((state: RootState) => state.inbox);

  useEffect(() => {
    dispatch(getHelpList());
  }, []);

  const handleQuestionClick = (index: number) => {
    if (selectedQuestion === index) {
      setSelectedQuestion(null);
    } else {
      setSelectedQuestion(index);
    }
  };

  return (
    <div className="HelpSection">
      <h2 className="HelpSection__title">Frequently Asked Questions</h2>
      {loading ? (
        <Loader />
      ) : (
        <div className="HelpSection__main">
          {helpList?.map(
            (a: { question: string; answer: string }, index: number) => (
              <div key={a.question} className="HelpSection__button-wrapper">
                <button
                  type="button"
                  onClick={() => handleQuestionClick(index + 1)}
                  className={`HelpSection__button ${
                    selectedQuestion === index + 1 &&
                    'HelpSection__button--active'
                  }`}
                >
                  {a.question}
                  <img
                    src={chevron}
                    alt="arrow"
                    style={{
                      transform:
                        selectedQuestion === index + 1
                          ? 'rotate(180deg)'
                          : 'rotate(0)',
                    }}
                  />
                </button>
                {
                  selectedQuestion === index + 1 &&
                    (() => {
                      const match = a.answer.match(
                        /\[(.*?)]\(http:\/\/(.*?)\)/,
                      );
                      if (match) {
                        const split = a.answer.split(match[0]);
                        return (
                          <p className="HelpSection__info">
                            {split[0]}
                            <a href="/privacy">{match[1]}</a>
                            {split[1]}
                          </p>
                        );
                      }
                      return <p className="HelpSection__info">{a.answer}</p>;
                    })()
                  // <p className="HelpSection__info">{a.answer}</p>
                }
              </div>
            ),
          )}
        </div>
      )}
    </div>
  );
}
