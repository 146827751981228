import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Artifact, SendTemplateObj, Template } from '../../types/templates';
import {
  sendTemplate,
  getTemplates,
  getArtifacts,
  getArtifact,
  sendTemplateFromFile,
  removeArtifact,
  fetchUploadCSV,
  getLazyArtifact,
  getQuestions,
  getTemplateTypes
} from '../../api/templates';

export interface TemplatesState {
  templates: any[];
  loading: boolean;
  stories: Artifact[];
  artifacts: Artifact[];
  artifactsCount: number;
  csv: string[];
  hasError: boolean;
  questions: any;
  stopFetchTemplates: boolean;
  activeTemplate: any;
  types: string[];
}

const initialState: TemplatesState = {
  templates: [],
  loading: false,
  stories: [],
  artifacts: [],
  artifactsCount: 0,
  csv: [],
  hasError: false,
  questions: [],
  stopFetchTemplates: false,
  activeTemplate: null,
  types: [],
};

const delay = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export const getTemplatesTypes = createAsyncThunk(
  'templates/getTemplateTypes',
  async () => {
    try {
      const res = await getTemplateTypes();
      return res?.data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const getAllTemplates = createAsyncThunk(
  'templates/getAllTemplates',
  async (query: number, { rejectWithValue, dispatch }) => {
    dispatch(startLoading());
    try {
      const res = await getLazyArtifact(query);
      dispatch(endLoading());
      return res?.data;
    } catch (error) {
      dispatch(endLoading());
    }
  },
);

export const getTemplateQuestion = createAsyncThunk(
  'templates/getTemplateQuestion',
  async (id: number, { rejectWithValue, dispatch }) => {
    dispatch(startLoading());
    try {
      const res = await getQuestions(id);
      dispatch(endLoading());
      return res?.data;
    } catch (error) {
      dispatch(endLoading());
    }
  },
);

export const uploadCSV = createAsyncThunk(
  'templates/uploadCSV',
  async () => {
    await fetchUploadCSV();
  }
);

export const createArtifacts = createAsyncThunk(
  'templates/createArtifacts',
  async (artifacts: SendTemplateObj[], { rejectWithValue, dispatch }) => {
    dispatch(startLoading());
    try {
      const res: {data: Artifact[]} = await sendTemplate(artifacts);

      console.log(res);

      const result = await Promise.all(res.data.map(async (artifact) => {
        let res: any & {data: Artifact};
        do {
          res = await getArtifact(artifact.id);

          try {
            if (res.data.status === 'generating') {
              await delay(5000);
            } else break;
          } catch (err) {
            break;
          }
        } while (res.data.status === 'generating');
        return res.data;
      }));
      dispatch(endLoading());
      return result
    } catch (error) {
      dispatch(endLoading());
    }
  },
);

export const getAllArtifacts = createAsyncThunk(
  'templates/getAllArtifacts',
  async (data: { skip: number; search: string, filter: string }) => {
    try {
      const res = await getArtifacts(data);

      return res.data;
    } catch (error) {
    }
  },
);

export const createStoryFromFile = createAsyncThunk(
  'templates/createStoryFromFile',
  async (data: File, { rejectWithValue, dispatch }) => {
    dispatch(startLoading());
    try {
      const sendRes = await sendTemplateFromFile(data);
      const result: Artifact[] = [];

      await Promise.all(
        sendRes?.data?.map(async (info: any) => {
          let res = {
            data: {
              text: ""
            }
          } as {
            data: Artifact
          };
        
          const delay = (ms: number) => {
            return new Promise(resolve => setTimeout(resolve, ms))
          }

          const loop = async () => {
            while (res?.data?.text?.length === 0) {
              await delay(5000);
              try {
                res = await getArtifact(info?.id);
              } catch (error) {
                dispatch(endLoading());
                return;
              }
            }

            if (res?.data?.text?.length > 0) {
              result.push(res?.data);
            }
          }

          return loop();
        })
      );

      dispatch(endLoading());
      return result;
    } catch (error) {
      dispatch(endLoading());
    }
  },
);

export const deleteArtifact = createAsyncThunk(
  'templates/deleteArtifact',
  async (id: number) => {
    try {
      await removeArtifact(id);
      return id
    } catch (error) {
    }
  },
);

export const templatesSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    clearTemplate: (state) => {
      state.templates = [];
      state.loading = false;
      state.stories = [];
      state.artifacts = [];
      state.artifactsCount = 0;

    },
    startLoading: (state) => {
      state.loading = true;
    },
    endLoading: (state) => {
      state.loading = false;
    },
    resetArtifacts: (state) => {
      state.artifacts = [];
    },
    clearQuestions: (state) => {
      state.questions = [];
    },
    removeArtifact: (state, action: {type: string, payload: number}) => {
      state.artifacts = [...state.artifacts.filter((item: Artifact) => item.id !== action.payload)];
    },
    csvImport: (state, action: {type: string, payload: string[]}) => {
      state.csv = action.payload;
    },
    setHasError: (state, action: {type: string, payload: boolean}) => {
      state.hasError = action.payload;
    },
    startFetchingAgain: (state) => {
      state.stopFetchTemplates = false;
    },
    setActiveTemplate: (state, action: {type: string, payload: any}) => {
      state.activeTemplate = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(getAllTemplates.fulfilled, (state, action: PayloadAction<Template[]>) => {
      if (!action.payload.length) {
        state.stopFetchTemplates = true;
      }

      const idMap = new Map();

      [...state.templates, ...action.payload].forEach(obj => {
        if (!idMap.has(obj.id)) {
          idMap.set(obj.id, obj);
        }
      });

      state.templates = Array.from(idMap.values());
    })
    .addCase(createArtifacts.fulfilled, (state, action: any) => {
      state.stories = [...action.payload];
    })
    .addCase(getTemplateQuestion.fulfilled, (state, action: any) => {
      state.questions = [...action.payload];
    })
    .addCase(createStoryFromFile.fulfilled, (state, action: any) => {
      state.stories = [...action.payload];
    })
    .addCase(getAllArtifacts.fulfilled, (state, action: PayloadAction<{ count: number; list: Artifact[] }>) => {
      state.artifactsCount = action.payload?.count;
      const newState = [...state.artifacts, ...action.payload?.list]?.filter((object: Artifact, index: number, self: Artifact[]) =>
        index === self?.findIndex((o: Artifact) => o?.id === object?.id)).sort((a: Artifact, b: Artifact) => b?.id - a?.id);

      state.artifacts = newState;
    })
    .addCase(getTemplatesTypes.fulfilled, (state, action: any) => {
      state.types = action.payload;
    })
    .addCase(deleteArtifact.fulfilled, (state, action: any) => {
      state.artifacts = state.artifacts.filter((a: Artifact) => +a?.id !== action.payload);
      state.stories = state.stories.filter((a: Artifact) => +a?.id !== action.payload);
    })
  },
});

export const { 
  csvImport, 
  clearTemplate, 
  startLoading, 
  endLoading, 
  resetArtifacts,
  setHasError,
  clearQuestions,
  startFetchingAgain,
  setActiveTemplate
} = templatesSlice.actions;

export default templatesSlice.reducer;
