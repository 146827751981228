import React, { useState } from 'react';
import './OptionalField.scss';

interface OptionalFieldProps {
  onChange: (name: string, value: string | boolean | number) => void;
  title: string;
  input: 'text' | 'range' | 'checkbox' | 'select';
  default: string | boolean | number;
  select?: string[];
  name: string;
  min?: number;
  max?: number;
}
function OptionalField({
  onChange,
  title,
  input,
  default: defaultValue,
  select,
  name,
  min,
  max,
}: OptionalFieldProps) {
  const [selectValue, setSelectValue] = useState(
    input === 'select' && defaultValue,
  );
  const [rangeValue, setRangeValue] = useState(1);
  const [checked, setChecked] = useState(false);

  if (input === 'range') {
    return (
      <div className="optional-field">
        <h4 className="optional-field__title">{title}</h4>
        <div className="optional-field__input-range">
          <label htmlFor={`option_select_${name}`}>
            <span>{rangeValue}</span>
            <input
              id={`option_select_${name}`}
              type="range"
              min={min}
              max={max}
              value={rangeValue}
              onChange={(e) => {
                onChange(name, Number(e.target.value));
                setRangeValue(Number(e.target.value));
              }}
            />
          </label>
        </div>
      </div>
    );
  }
  if (input === 'checkbox') {
    return (
      <div className="optional-field">
        <h4 className="optional-field__title">{title}</h4>
        <div className="optional-field__input-checkbox">
          <label htmlFor={`option_select_${name}`}>
            <input
              id={`option_select_${name}`}
              type="checkbox"
              checked={checked}
              onChange={() => {
                onChange(name, !checked);
                setChecked(!checked);
              }}
            />
            <div>
              <span>ON</span>
              <span />
              <span>OFF</span>
            </div>
          </label>
        </div>
      </div>
    );
  }
  if (input === 'select' && select) {
    return (
      <div className="optional-field">
        <h4 className="optional-field__title">{title}</h4>
        <div className="optional-field__input-select">
          {select?.map((option) => (
            <label htmlFor={`optional_select_${option}`} key={option}>
              <input
                name={name}
                id={`optional_select_${option}`}
                type="radio"
                checked={selectValue === option}
                onChange={() => {
                  onChange(name, option);
                  setSelectValue(option);
                }}
              />
              <span>{option}</span>
            </label>
          ))}
        </div>
      </div>
    );
  }
  return <div className="optional-field" />;
}

OptionalField.defaultProps = {
  select: [],
  min: 1,
  max: 10,
};

export default OptionalField;
