import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import LoginPageBtn from '../../Buttons/LoginPageBtn';
import AuthInput from '../LoginForm/AuthInput';
import { RecoverPassApi } from '../../../api/auth';

import logo from '../../../images/utils-icons/AgileGPT.svg';
import { RootState } from '../../../redux/store';

export default function NewPass() {
  const navigate = useNavigate();
  const params = useParams();

  const [formData, setFormData] = useState({
    password: '',
    proovePassword: '',
  });

  const [errors, setErrors] = useState({
    password: '',
    proovePassword: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: '' });
  };

  const handleBlur = (name: string, value: string) => {
    if (!value) {
      setErrors({
        ...errors,
        [name]: 'Password is required',
      });

      return 'Password is required';
    }

    if (value.length < 6) {
      setErrors({
        ...errors,
        [name]: 'Password must contain at least 6 characters',
      });

      return 'Password must contain at least 6 characters';
    }

    if (formData.password !== formData.proovePassword) {
      setErrors({
        ...errors,
        proovePassword: 'Passwords do not match',
      });

      return 'Passwords do not match';
    }

    return '';
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const passwordError = handleBlur('password', formData.password);
    const proovePasswordError = handleBlur(
      'proovePassword',
      formData.proovePassword,
    );

    setErrors({
      ...errors,
      password: passwordError,
      proovePassword: proovePasswordError,
    });

    const { password, proovePassword } = errors;

    if (!proovePassword && !password) {
      await RecoverPassApi({
        tokenPart: Object.values(params)[0] || '',
        password: formData?.password,
      });
      navigate('/');
    }
  };

  return (
    <div className="LoginPage">
      <img src={logo} alt="logo" className="LoginPage__logo" />
      <form className="Login" onSubmit={handleSubmit}>
        <h2 className="Login__title">New Password</h2>
        <AuthInput
          label="Password"
          id="password"
          name="password"
          placeholder="Enter new password"
          value={formData.password}
          onChange={handleChange}
          onBlur={() => handleBlur('password', formData.password)}
          error={errors.password}
        />
        <AuthInput
          label="Repeat password"
          id="proovePassword"
          name="proovePassword"
          placeholder="Repeat new password"
          value={formData.proovePassword}
          onChange={handleChange}
          onBlur={() => handleBlur('password', formData.proovePassword)}
          error={errors.proovePassword}
        />
        <button type="submit" className="LoginForm__submit-btn">
          <LoginPageBtn text="Submit" />
        </button>
      </form>
      <div className="LoginPage__btns">
        <button
          type="button"
          className="LoginPage__privacy"
          onClick={() => navigate('/terms')}
        >
          Terms and conditions
        </button>
        <button
          type="button"
          className="LoginPage__privacy"
          onClick={() => navigate('/privacy')}
        >
          Privacy policy
        </button>
      </div>
    </div>
  );
}
