/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React from 'react';
import './Modal.scss';

interface ModalProps {
  type: 'delete' | 'confirm';
  onSubmit: () => void;
  onCancel: () => void;
  submitBtnText: string;
  cancelBtnText: string;
  title: string;
  text: string;
}

export default function Modal({
  type,
  onSubmit,
  onCancel,
  submitBtnText,
  cancelBtnText,
  title,
  text,
}: ModalProps) {
  return (
    <div className="modal">
      <div
        className="modal__overlay"
        onClick={(e) => {
          if (e.target === e.currentTarget) {
            onCancel();
          }
        }}
      />
      <div className="modal__content">
        <button className="modal__close" type="button" onClick={onCancel}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15 5L5 15"
              stroke="#686868"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5 5L15 15"
              stroke="#686868"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <h3 className="modal__title">{title}</h3>
        <p className="modal__text">{text}</p>
        <div className="modal__btns">
          {submitBtnText && (
            <button
              className={`modal__btn modal__btn--${type}`}
              type="button"
              onClick={onSubmit}
            >
              {submitBtnText}
            </button>
          )}
          <button
            className="modal__btn modal__btn--cancel"
            type="button"
            onClick={onCancel}
          >
            {cancelBtnText}
          </button>
        </div>
      </div>
    </div>
  );
}
